import gql from "graphql-tag";

import { CheckoutFragment } from "../fragments";
/*
export const createCheckout = gql`
  mutation checkoutCreate($CardItem: CheckoutCreateInput!) {
    checkoutCreate(input: $CardItem) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;*/

export const createCheckout = gql`
 mutation cartCreate($CardItem: CartInput) {
    cartCreate(input: $CardItem) {
    cart {
      ...CheckoutFragment
    }
    userErrors {
      field
      message
    }
  }
}
${CheckoutFragment}
  `;