
import gql from 'graphql-tag';
export const customerRecover = gql`
 mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }
 `;
