
import gql from "graphql-tag";

import { CheckoutFragment } from "../fragments";
/*
export const checkoutCustomerAssociateV2 = gql`
mutation checkoutCustomerAssociateV2($checkoutId: ID!, $customerAccessToken: String!) {
    checkoutCustomerAssociateV2(checkoutId: $checkoutId, customerAccessToken: $customerAccessToken) {
      userErrors {
        field
        message
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;
*/

export const cartBuyerIdentityUpdate = gql`
mutation cartBuyerIdentityUpdate($checkoutId: ID!, $buyerIdentityInput: CartBuyerIdentityInput!) {
  cartBuyerIdentityUpdate(cartId: $checkoutId, buyerIdentity: $buyerIdentityInput) {
    cart {
      id
      buyerIdentity {
        email
        phone
        countryCode
        customer {
          id
        }
      }
    }
  }
}
  `;