


import gql from "graphql-tag";

import { CheckoutFragment } from "../fragments";
/*
export const checkoutLineItemsRemove = gql`
  mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(
      checkoutId: $checkoutId
      lineItemIds: $lineItemIds
    ) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;
*/
export const checkoutLineItemsRemove = gql`
mutation cartLinesRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
  cartLinesRemove(cartId: $checkoutId, lineIds: $lineItemIds) {
    cart {
      ...CheckoutFragment
    }
    userErrors {
      field
      message
    }
  }
}
 ${CheckoutFragment}
`;