import React, { useState, useEffect } from 'react'
import {
    // Card,
    Button,
    Modal,
    Alert,
    Form
} from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { customerRecover } from '../grappql/Customers/Mutations/customerRecover'



export const ForgotPassword = (props) => {


    const [RecoverPassword, {
        loading: recoverging_passsword,
        data: recovered_data,
        error: recover_error
    }
    ] = useMutation(customerRecover);




    const [userEmail, setUserEmail] = useState("");



    const [SuccessMessage, setSuccessMessage] = useState(undefined);
    const [ErrorMessage, setErrorMessage] = useState(undefined);
    const [SomethingUpadating, setSomethingUpadating] = useState(false);


    const submit = (e) => {
        e.preventDefault();
        setSuccessMessage(undefined);
        setErrorMessage(undefined);
        setSomethingUpadating(false);

        if (!userEmail) {
            setErrorMessage("Email field is required")
        }
        else {
            setSomethingUpadating(true);
            RecoverPassword({
                variables: {
                    email: userEmail
                }
            })
        }
    }



    useEffect(() => {
        if (recovered_data != undefined) {

            if (recovered_data.customerRecover.customerUserErrors.length) {
                recovered_data.customerRecover.customerUserErrors.forEach(function (error) {
                    setErrorMessage(error.message);
                    setSomethingUpadating(false);
                })
            }
            else {
               // console.log(recovered_data);
                setSomethingUpadating(false)
                setSuccessMessage("Reset password link has been sent to your email");
            }
        }
    }, [recovered_data])


    const openLoginPopup = () => {
        props.forgot_password_popup_hide();
        props.ShowLoginPopup();
    }


    return (
        <>
            <Modal
                className="login_modal"
                show={props.forgot_password_popup}
                onHide={props.forgot_password_popup_hide}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton className="border-0">
                </Modal.Header>
                <Modal.Body>
                    <Form className="" onSubmit={submit}>
                        <Modal.Title className="text-center">Forgot Your Password ?</Modal.Title>
                        <p className="text-center">We will send you an email to reset your password.</p>

                        {SuccessMessage == undefined
                            ?
                            <Form.Group className="mb-3" >
                                <Form.Control type="email" onChange={(e) => setUserEmail(e.target.value)} value={userEmail} placeholder="Enter email" />
                            </Form.Group>
                            : ''
                        }
                        {ErrorMessage !== undefined ?
                            <Alert variant="danger">
                                {ErrorMessage}
                            </Alert>
                            : ''}
                        {SuccessMessage !== undefined ? <>
                            <Alert variant="success">
                                {SuccessMessage}
                            </Alert>
                            <Button className="w-100 rounded-2 btn-lg border-2" onClick={(e) => openLoginPopup()}>Login</Button></>
                            : ''}
                        {SomethingUpadating === true ?
                            <Alert variant="primary">
                                Validating...
                            </Alert>
                            : ''}


                        {SuccessMessage == undefined
                            ?
                            <>
                            <div className="text-center d-flex justify-content-between">
                                <Button variant="primary" type="submit" className="rounded-2 btn-lg border-2 ms-0 m-2 w-50">Submit</Button> 
                                <Button variant="outline-secondary" type="submit" className="rounded-2 btn-lg border-2 m-2 me-0 w-50" 
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.ShowLoginPopup();
                                    props.forgot_password_popup_hide();
                                    } }> Cancel</Button> 
                            </div>
                            </>
                            : ''
                            }
                    </Form>

                    <p className="text-center mt-5">Forgot your registered email ? <a className="text-secondary" onClick={(e) => {
                        props.forgot_password_popup_hide();
                        props.forgot_username_popup_show();
                    } }>Click here</a></p>



                </Modal.Body>
            </Modal>
        </>
    )
}
