import gql from 'graphql-tag';

export const customerResetByUrl = gql`
mutation customerResetByUrl($password: String!, $resetUrl: URL!) {
    customerResetByUrl(password: $password, resetUrl: $resetUrl) {
      customer { id }
      customerUserErrors { code field message}
    }
  }
  
  `;