import gql from 'graphql-tag';

export const FetchProductData = gql`
query ($handle: String!) {
  product(handle: $handle) {
        id
        handle
        title
        tags
        productType
        vendor
        description
        descriptionHtml
        priceRange
        {
          minVariantPrice
          {
            amount
            currencyCode
          }
        }
        compareAtPriceRange { minVariantPrice { amount } }
        options
        {
            id
            name
            values
        }
      
        variants(first: 5) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              node {
                id
                title
                sku
                selectedOptions {
                  name
                  value
                }
               image {
                  url
                }
              }
            }
          }
          metafields(identifiers: [{namespace: "arena", key: "all_data"}])
          {
                 key
                 value
                 namespace
          } 
    }
  }`;