import gql from "graphql-tag";

export const CheckoutFragment = gql`
  fragment CheckoutFragment on Cart {
    id
    checkoutUrl
    cost {
      subtotalAmount {
        amount
        currencyCode
      }
      totalAmount {
        amount
        currencyCode
      }
    }
    lines(first: 50) {
      edges {
        node {
          id
          quantity
          merchandise {
             ... on ProductVariant {
              id
              product{
                id
                title
                handle
              }
              price{
                amount
                currencyCode
              }
            }
          }      
        }
      }
    }
  }
`;
