
import gql from "graphql-tag";

import { CheckoutFragment } from "../fragments";

/*
export const checkoutLineItemsUpdate = gql`
  mutation checkoutLineItemsUpdate(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemUpdateInput!]!
  ) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;
*/
export const checkoutLineItemsUpdate = gql`
mutation cartLinesUpdate($checkoutId: ID!, $lines: [CartLineUpdateInput!]!) {
  cartLinesUpdate(cartId: $checkoutId, lines: $lines) {
    cart {
      ...CheckoutFragment
    }
    userErrors {
      field
      message
    }
  }
}
${CheckoutFragment}
`;