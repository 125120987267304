import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import TypeProgram from './Single-program-types/type-program';
import TypeBook from './Single-program-types/type-book';
import TypeWebcast from './Single-program-types/type-webcast';
import { Helmet } from "react-helmet";
import { FetchProductData } from '../grappql/Product/Queries/Product';
import {
  LoaderIcon
} from "../../icons";
import { useHistory } from "react-router-dom"

import {
  Container,
} from 'react-bootstrap';
// import Swiper core and required modules
import SwiperCore, {
  Navigation
} from 'swiper';
// install Swiper modules
SwiperCore.use([Navigation]);




export const SIngleProduct = (props) => {
  let history = useHistory()

  /***
   * Producgram structure images
   */


  const [Program_structure_image, setProgram_structure_image] = useState(['https://engine.leocussen.edu.au/sync/img/logo.png']);

  // Single Product states start

 

  const [relatedProducts, setRelatedProducts] = useState([]);
  const [rdata, setRdata] = useState([]);
  const [recordType, setRecordType] = useState('');
  const [programType, setprogramType] = useState(undefined);
  const [quantity, setQuantity] = useState(1);
  const [priceRange, setpriceRange] = useState(0);
  const [compareAtPriceRange, setcompareAtPriceRange] = useState(0);
  const [SingleVariants, setSingleVariant] = useState();
  const [seo_title, set_seo_title] = useState('');
  const [seo_description, set_seo_description] = useState('');
  const [reached_capacity, setreached_capacity] = useState(false);

  // single product states ends

  // NEW API DATA STATES
  const [single_program_data, set_single_program_data] = useState([]);
  const [single_program_basic_data, set_single_program_basic_data] = useState([]);
  const [window_width, set_window_width] = useState(window.innerWidth)
  const [presontor_length, set_presontor_length] = useState(0)
  const [related_program_length, set_related_program_length] = useState(0)
  // NEW API DATA STATES ENDS

  const increaseQuantity = (quantity) => {
    setQuantity(quantity);
  };

  const decreaseQuantity = (quantity) => {
    quantity = quantity < 1 ? 1 : quantity;
    setQuantity(quantity);
  };

  const changeQuantity = (e) => {
    let new_quantity = e.target.value;
    new_quantity = new_quantity < 1 ? 1 : new_quantity;
    setQuantity(new_quantity);
  }

  const [loadEntries, { data: entriesData }] = useLazyQuery(FetchProductData);
  const entries =
    entriesData && entriesData.getEntriesByHabitId
      ? entriesData.getEntriesByHabitId
      : [];


  const location = useLocation();
  const PathName = location.pathname;
  const productHandle = PathName.substring(PathName.lastIndexOf('/') + 1);
  const test = {
    handle: productHandle
  }
  const [SpecificProduct, setSpecific] = useState();
  const [CurrentVariant, setCurrentVariant] = useState({ price: 0 });
  const [testTitle, setTest] = useState();
  const [optionList, setOptionList] = useState();

  const [cpd_program_delivery_mode, set_cpd_program_delivery_mode] = useState(undefined);
  const [Program_Types__c_color, setProgram_Types__c_color] = useState('#bc318c');


  const getRelatedProducts = async (related_product_slug, product_number) => {
    // Perform save operation

    const relatedProductDetail = await fetchMore({
      variables: {
        handle: related_product_slug
      },
      updateQuery() {

      }
    });

    const related_title = relatedProductDetail.data.product.title;
    const related_description = relatedProductDetail.data.product.description;
    const related_metafields = relatedProductDetail.data.product.metafields;
    let related_venue = '';
    if (related_metafields.edges.length > 0) {
      related_metafields.edges.forEach((related_meta_val, related_meta_key) => {
        if (related_meta_val.key == 'venue') {
          related_venue = related_meta_val.node.value;
        }
      });
    }
    const related_data = {
      title: related_title,
      description: related_description > 300 ? related_description.substring(0, 300) + '...' : related_description,
      slug: related_product_slug,
      related_venue: related_venue,
    };

    let new_data = rdata;
    if (related_product_slug != productHandle) {
      new_data.push(related_data);
    }
    setRdata([new_data]);
  };
  const { loading, error, data, fetchMore } = useQuery(FetchProductData, {
    variables: {
      handle: productHandle
    }
  });

  let selectedOptions = [];
  let variant_list = undefined;
  useEffect(() => {
    if (data != undefined) {
      DataLoaded(data);
    }
  }, [data]);

  useEffect(() => {
    if (relatedProducts.length > 0) {
      let related_product_count = 1;
      relatedProducts.forEach((related_val, key) => {
        let related_product = getRelatedProducts(related_val, related_product_count);
        related_product_count = related_product_count + 1;
      });

    }

  }, [relatedProducts]);







  /***
   * Social share
   */
  const [Clipboard, setClipboard] = useState("Copy Link to Clipboard");
  const copyToClipboard = (url) => {
    var textField = document.createElement('textarea')
    textField.innerText = url
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
  }

  const FBshare = () => {
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.location.href + '&title=' + data.product.title, 'fbShareWindow', 'height=450, width=550, top=' + (window.innerHeight / 2 - 275) + ', left=' + (window.innerWidth / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
  }

  const TwitterShare = () => {
    window.open('https://twitter.com/intent/tweet?url=' + window.location.href + '&text=' + data.product.title + ' ' + seo_description, 'fbShareWindow', 'height=450, width=550, top=' + (window.innerHeight / 2 - 275) + ', left=' + (window.innerWidth / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
  }

  const LinkedinShare = () => {
    window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + window.location.href + '&text=' + data.product.title, 'fbShareWindow', 'height=450, width=550, top=' + (window.innerHeight / 2 - 275) + ', left=' + (window.innerWidth / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
  }

  const emailShare = () => {
    //window.open('https://www.linkedin.com/shareArticle?mini=true&url='+window.location.href+'&text='+data.product.title, 'fbShareWindow', 'height=450, width=550, top=' + (window.innerHeight / 2 - 275) + ', left=' + (window.innerWidth / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
    window.location.href = "mailto:?&subject=" + data.product.title + "&cc=&bcc=&body=" + window.location.href;
  }

  const CopyLink = (e) => {
    copyToClipboard(window.location.href);
    setClipboard("Copied");
  }

  const RevetCopyText = () => {
    setClipboard("Copy Link to Clipboard");
  }


  useEffect(() => {
    if (data !== undefined && data.product !== undefined && data.product !== null) {
      setSingleVariant(data.product.variants.edges[0].node);
    }
  }, [data])


  if (loading) {
    return <Container><div className="pre_loader"><LoaderIcon /></div></Container>
  }
  if (error) {
    return <Container><h1>{JSON.stringify(error)}</h1></Container>
  }

  const DataLoaded = (data) => {

    // NEW API DATA STARTS
    if (data.product !== undefined && data.product !== null && data.product.metafields !== null && data.product.metafields !== undefined) {
      let basic_data = {
        title: data.product.title,
        description: data.product.descriptionHtml,
        product_code: data.product.variants.edges[0].node.sku,
        price: data.product.priceRange !== undefined && data.product.priceRange.minVariantPrice !== undefined ? data.product.priceRange.minVariantPrice.amount : 0
      };
      set_seo_title(data.product.title);
      set_seo_description(data.product.description.substring(0, 157));


      props.Program_structure_images.filter(function (item, value) {
        var keyname = Object.keys(item);
        var tag_array = data.product.tags;
        if (tag_array.includes(keyname.toString())) {
          setProgram_structure_image(Object.values(item));
        }
      })
      props.Program_structure_color.filter(function (item, value) {
        var keyname = Object.keys(item);
        var tag_array = data.product.tags;
        if (tag_array.includes(keyname.toString())) {
            setProgram_Types__c_color(Object.values(item));
        }
    })


      let new_data = JSON.parse(data.product.metafields[0].value);
      setRecordType(new_data.RecordType);
      setprogramType(new_data.Program_Types__c);


      setreached_capacity(new_data.Reached_Capacity__c);
      // console.log(new_data);

     
      set_single_program_basic_data(basic_data);
      set_single_program_data(new_data);
      set_presontor_length(new_data.presentor !== null && new_data.presentor !== undefined ? new_data.presentor.length : 0);
      set_related_program_length(new_data.related_program !== null && new_data.related_program !== undefined ? new_data.related_program.length : 0);

    }
    // NEW API DATA ENDS




    if (data.product) {
      setSpecific(data);
      if (data.product.variants.edges.length) {
        setCurrentVariant(data.product.variants.edges[0].node);
      }

      data.product.options.map((option) => {
        let keyname_ = option.name;
        let keyvalue = option.values[0];
        let test = { keyname_, keyvalue }
        selectedOptions[keyname_] = keyvalue;

      });
      setOptionList(selectedOptions);

      if (data.product.metafields.length > 0) {
        setQuantity(1);
      }

    }

  }

  const handleOptionChange = (event) => {

    const target = event.target
    let mokdi = optionList;



    mokdi[target.name] = target.value;
    setOptionList(mokdi);

    //  selectedOptions[target.name] = target.value;
    // console.log(mokdi);

    if (data.product.variants.edges.length) {
      const selectedVariant_ = data.product.variants.edges.filter((variant) => {
        return variant.node.selectedOptions.every((selectedOption) => {
          return mokdi[selectedOption.name] === selectedOption.value;
        });
      })[0].node;
      // console.log(selectedVariant_);
      setCurrentVariant(selectedVariant_)

    }

  }




  var CurrentDate = new Date();

 




  /****
  * 10Minut over
  */
  const is_ten_minite_less = (program_start_date_time) => {
    if (program_start_date_time == null) {
      return false;
    }

    program_start_date_time = new Date(program_start_date_time);
    let program_time_modify = program_start_date_time.setHours(program_start_date_time.getHours());
    let current_time_10 = new Date(new Date().getTime() + 1000 * 600);
    let current_time = new Date().getTime();

    var from = current_time;  // -1 because months are from 0 to 11
    var to = current_time_10;
    var check = program_time_modify;

    return from <= check && check <= to;
  }


  /***
   * Is program over
   */

  const program_date_is_gone_or_not = (program_start_date_time) => {
    if (program_start_date_time === undefined || program_start_date_time===null || program_start_date_time==="") {
      return false;
    }
    program_start_date_time = new Date(program_start_date_time);
    let program_time_modify = program_start_date_time.setHours(program_start_date_time.getHours());
    let current_time = new Date().getTime();
    if ((parseFloat(program_time_modify) - parseFloat(current_time)) > 0) {
      return false;
    }
    else {
      return true;
    }
  }


  if (data != undefined && data.product !== undefined && data.product !== null && single_program_data !== undefined && single_program_basic_data !== undefined && Object.keys(single_program_data).length > 0 && Object.keys(single_program_basic_data).length > 0) {
    return (

      <>
        <Helmet>
          <title>{seo_title} | Leo Cussen Centre for Law</title>
          <link rel="canonical" href={"https://store.leocussen.edu.au/product/"+data.product.handle} />
          <meta name="description" content={seo_description} />
        </Helmet>
        {
          recordType !== undefined && recordType == 'CPD Programs' ?

            <TypeProgram
              tags={data.product.tags}
              handle={data.product.handle}
              single_program_data={single_program_data}
              single_program_basic_data={single_program_basic_data}
              AddtoCart={props.AddtoCart}
              SingleVariants={SingleVariants}
              FBshare={FBshare}
              TwitterShare={TwitterShare}
              LinkedinShare={LinkedinShare}
              emailShare={emailShare}
              CopyLink={CopyLink}
              Clipboard={Clipboard}
              recordType={recordType}
              programType={programType}
              RevetCopyText={RevetCopyText}
              increaseQuantity={increaseQuantity}
              decreaseQuantity={decreaseQuantity}
              quantity={quantity}
              Program_structure_image={Program_structure_image}
              product_page_type="leo_product_programs"
              reached_capacity={reached_capacity}
              is_ten_minite_less={is_ten_minite_less}
              getAllWishlist={props.getAllWishlist}
              AddtoWishlist={props.AddtoWishlist}
              Program_Types__c_color={Program_Types__c_color}
              program_date_is_gone_or_not={program_date_is_gone_or_not}
            />
            : null
        }
        {
          recordType !== undefined && recordType == 'Book' ?

            <TypeBook
              tags={data.product.tags}
              handle={data.product.handle}
              single_program_data={single_program_data}
              single_program_basic_data={single_program_basic_data}
              AddtoCart={props.AddtoCart}
              SingleVariants={SingleVariants}
              FBshare={FBshare}
              TwitterShare={TwitterShare}
              LinkedinShare={LinkedinShare}
              emailShare={emailShare}
              CopyLink={CopyLink}
              recordType={recordType}
              programType={programType}
              Clipboard={Clipboard}
              RevetCopyText={RevetCopyText}
              increaseQuantity={increaseQuantity}
              decreaseQuantity={decreaseQuantity}
              Program_structure_image={Program_structure_image}
              quantity={quantity}
              product_page_type="leo_product_book"
              reached_capacity={reached_capacity}
              getAllWishlist={props.getAllWishlist}
              AddtoWishlist={props.AddtoWishlist}
              Program_Types__c_color={Program_Types__c_color}
              program_date_is_gone_or_not={program_date_is_gone_or_not}


            />
            : null
        }
        {
          recordType !== undefined && recordType == 'Online Intensive' ?

            <TypeWebcast
              tags={data.product.tags}
              handle={data.product.handle}
              single_program_data={single_program_data}
              single_program_basic_data={single_program_basic_data}
              priceRange={priceRange}
              compareAtPriceRange={compareAtPriceRange}
              AddtoCart={props.AddtoCart}
              SingleVariants={SingleVariants}
              FBshare={FBshare}
              recordType={recordType}
              programType={programType}
              TwitterShare={TwitterShare}
              LinkedinShare={LinkedinShare}
              emailShare={emailShare}
              CopyLink={CopyLink}
              Clipboard={Clipboard}
              RevetCopyText={RevetCopyText}
              increaseQuantity={increaseQuantity}
              decreaseQuantity={decreaseQuantity}
              Program_structure_image={Program_structure_image}
              quantity={quantity}
              product_page_type="leo_product_webcast"
              reached_capacity={reached_capacity}
              is_ten_minite_less={is_ten_minite_less}
              getAllWishlist={props.getAllWishlist}
              AddtoWishlist={props.AddtoWishlist}
              Program_Types__c_color={Program_Types__c_color}
              program_date_is_gone_or_not={program_date_is_gone_or_not}

            />
            : null
        }
        {
          recordType != 'Online Intensive' && recordType != 'Book' && recordType != 'CPD Programs' ?
            <TypeProgram
              tags={data.product.tags}
              handle={data.product.handle}
              single_program_data={single_program_data}
              single_program_basic_data={single_program_basic_data}
              priceRange={priceRange}
              compareAtPriceRange={compareAtPriceRange}
              AddtoCart={props.AddtoCart}
              SingleVariants={SingleVariants}
              FBshare={FBshare}
              recordType={recordType}
              TwitterShare={TwitterShare}
              LinkedinShare={LinkedinShare}
              emailShare={emailShare}
              CopyLink={CopyLink}
              Clipboard={Clipboard}
              RevetCopyText={RevetCopyText}
              increaseQuantity={increaseQuantity}
              decreaseQuantity={decreaseQuantity}
              Program_structure_image={Program_structure_image}
              quantity={quantity}
              product_page_type="leo_product_programs"
              reached_capacity={reached_capacity}
              is_ten_minite_less={is_ten_minite_less}
              getAllWishlist={props.getAllWishlist}
              AddtoWishlist={props.AddtoWishlist}
              Program_Types__c_color={Program_Types__c_color}
              program_date_is_gone_or_not={program_date_is_gone_or_not}
            />
            : null
        }
        {/* {
          recordType !== undefined && recordType == 'Online Intensive' ?

            <TypeOnline
              single_program_data={single_program_data}
              single_program_basic_data={single_program_basic_data}
              title={data.product.title}
              authors={authors}
              related={rdata}
              cpd_program_delivery_mode={cpd_program_delivery_mode}
              short_promotion_message={shortPromotionMessage}
              total_points={totalCpdPoints}
              professional_skills={professional_skills}
              practise_managment_skill={practise_managment_skill}
              substantive_law={substantive_law}
              ethics_professional_responsibi={ethics_professional_responsibi}
              venue={venue}
              long_promotion_message={longPromotionMessage}
              descriptionHtml={data.product.descriptionHtml}
              description={data.product.description}
              code={code}
              date_format={dateFormat}
              designedFor={designedFor}
              Catering={Catering}
              available_from={dateFormat}
              available_untill={dateFormat2}
              length={length}
              priceRange={priceRange}
              compareAtPriceRange={compareAtPriceRange}
              AddtoCart={props.AddtoCart}
              SingleVariants={SingleVariants}
              FBshare={FBshare}
              TwitterShare={TwitterShare}
              LinkedinShare={LinkedinShare}
              emailShare={emailShare}
              CopyLink={CopyLink}
              Clipboard={Clipboard}
              RevetCopyText={RevetCopyText}
            />

            : null
        } */}

      </>
    )

  }

  if (data.product == null) {
    return (

      <div className="pre_loader text-center"><h2>Product Not Found.</h2></div>
    )
  }

  return (

    <div className="pre_loader text-center"><LoaderIcon /></div>
  )
}


