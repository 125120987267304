
import gql from 'graphql-tag';

export const customerActivateByUrl = gql`
mutation($activationUrl: URL!, $password: String!){
    customerActivateByUrl(
      activationUrl: $activationUrl,
      password: $password
    ) {
      customer { id }
      customerUserErrors { code field message }
    }
  } `;