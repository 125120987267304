import gql from "graphql-tag";

export  const GetuserAddress = gql`
query($customerAccessToken:String!){
    customer(customerAccessToken:$customerAccessToken){
    firstName
    lastName
    email
    phone
      addresses(first:10)
      {
        edges
        {
          node{
            address1
            address2
            city
            company
            zip
            country
            countryCodeV2
            firstName
            lastName
            phone
            id
            province
            provinceCode
          }
        }
      }
    }
  }
  `;