import React, {useState, useEffect} from "react";

import {useMutation} from "@apollo/react-hooks";
import {
  Row,
  Col,
  Button,
  Modal,
  Form,
  InputGroup,
  ProgressBar,
  Alert,
} from "react-bootstrap";

import axios from "axios";
import {PrivacyPolicy} from "../Modals/PrivacyPolicy";
import {useLocation} from "react-router";
import "react-phone-number-input/style.css";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";

export const CreateAccount = (props) => {
  const location = useLocation();

  const now = 40;

  const [validated, setValidated] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Mobile, setMobile] = useState("");
  const [newMobile, setnewMobile] = useState("");
  const [newlnumber, setnewlnumber] = useState("");
  const [Compamy, setCompany] = useState("");
  const [Phone, setPhone] = useState("");
  const [countryCode, setcountryCode] = useState("+61");
  const [countryCode_landline, setcountryCode_landline] = useState("+61");
  const [isLoading, setisLoading] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [privacyPolicyER, setPrivacyPolicyER] = useState(true);
  const [pp_show, set_pp_show] = useState(false);
  const HidePPPopup = () => set_pp_show(false);
  const [ReferalCode, setReferalCode] = useState("");

  //console.log(privacyPolicy);
  const [userEmail_ER, setUserEmail_ER] = useState(false);
  const [firstName_ER, setFirstName_ER] = useState(false);
  const [LastName_ER, setLastName_ER] = useState(false);
  const [Mobile_ER, setMobile_ER] = useState(false);
  const [Compamy_ER, setCompany_ER] = useState(false);
  const [Phone_ER, setPhone_ER] = useState(false);

  const lnumber = countryCode_landline + Phone.replace(/^0+/, "");
  const m_number = countryCode + Mobile.replace(/^0+/, "");

  const input = {
    email: userEmail,
    firstName: firstName,
    lastName: LastName,
    phone: newMobile,
  };

  const store_location_input = {
    email: userEmail,
    firstName: firstName,
    lastName: LastName,
    phone: newMobile,
    company: Compamy,
  };

  const [SuccessMessage, setSuccessMessage] = useState(undefined);
  const [ErrorMessage, setErrorMessage] = useState(undefined);
  const [SomethingUpadating, setSomethingUpadating] = useState(false);

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleSubmit = (event) => {
    setSuccessMessage(undefined);
    setErrorMessage(undefined);

    event.preventDefault();
    setErrorMessage("");
    setUserEmail_ER(false);
    setFirstName_ER(false);
    setLastName_ER(false);
    setMobile_ER(false);
    setPhone_ER(false);

    var setUserEmaDil_ER_var = true;
    var setFirstName_ER_var = true;
    var setLastName_ER_var = true;
    var setMobile_ER_var = true;
    var privacyPolicyER_var = true;

    if (!firstName) {
      setFirstName_ER(true);
      setFirstName_ER_var = false;
    }

    if (!LastName) {
      setLastName_ER(true);
      setLastName_ER_var = false;
    }

    if (!userEmail) {
      setUserEmail_ER(true);
      setUserEmaDil_ER_var = false;
    }
    if (!validateEmail(userEmail)) {
      setUserEmail_ER(true);
      setUserEmaDil_ER_var = false;
    }
    if (!newMobile) {
      setMobile_ER(true);
      setMobile_ER_var = false;
    }

    if (!isValidPhoneNumber(newMobile)) {
      // console.log('isValidPhoneNumber');
      setMobile_ER(true);
      setMobile_ER_var = false;
    }

    if (privacyPolicy === false) {
      //  console.log('ifff');
      setPrivacyPolicyER(false);
      privacyPolicyER_var = false;
    } else {
      //   console.log('elsee');

      setPrivacyPolicyER(true);
      privacyPolicyER_var = true;
    }

    setErrorMessage(undefined);
    setSomethingUpadating(false);

    if (
      setUserEmaDil_ER_var &&
      setFirstName_ER_var &&
      setLastName_ER_var &&
      setMobile_ER_var &&
      privacyPolicyER_var
    ) {
      setSomethingUpadating(true);
      localStorage.setItem(
        "create_account_detail",
        JSON.stringify(store_location_input)
      );
      localStorage.getItem("_loggedUserEmail", userEmail);
      //  console.log(input);
      // CreateUser({ variables: { input } });

      const finalDate1 = {
        shopify_customer_id: "",
        email: userEmail,
        first_name: firstName,
        last_name: LastName,
        landline: newlnumber,
        mobile_number: newMobile,
        company: Compamy,
      };

      const finalDate2 = {
        email: userEmail,
        first_name: firstName,
        last_name: LastName,
        landline: newlnumber,
        mobile_number: newMobile,
        company: Compamy,
        refferal_code: ReferalCode,
      };

      // console.log(finalDate);

      axios
        .post(props.EngineURL + "chk/update-contact.php", finalDate1)
        .then(function (response) {
          //  console.log("Salesforce account");
          //  console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });

      axios
        .post(
          props.EngineURL + "create-account/create_token_new.php",
          finalDate2
        )
        .then(function (response) {
          //  console.log("PHP response");
          //  console.log(response);

          setTimeout(function () {
            if (response != null) {
              if (response.data.code == 200) {
                setSuccessMessage(response.data.message);
              } else {
                setErrorMessage(response.data.message);
              }
            } else {
              setErrorMessage("Something went wrong!");
            }
            setSomethingUpadating(false);
          }, 1000);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setErrorMessage(
        "Validation errors occurred. Please check the entries and try again."
      );
    }
  };

  return (
    <>
      <Modal
        className="create_account_modal"
        size="lg"
        show={props.CreateAccountPopup}
        onHide={props.HideCreateAccountPopup}
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          {SuccessMessage == undefined ? (
            <Modal.Title as="h2" className="h1 text-center">
              Create Your Account
            </Modal.Title>
          ) : (
            ""
          )}
          <Form validated={validated} onSubmit={handleSubmit}>
            <Row>
              {SuccessMessage == undefined ? (
                <>
                  <Form.Group
                    as={Col}
                    md="6"
                    xs="12"
                    controlId="validationCustom01"
                    className="form-group"
                  >
                    <Form.Label htmlFor="validationCustom01">
                      First name*
                    </Form.Label>
                    <Form.Control
                      className={firstName_ER ? "form-error" : ""}
                      type="text"
                      id="validationCustom01"
                      placeholder="First name*"
                      defaultValue="John"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    xs="12"
                    controlId="validationCustom02"
                    className="form-group"
                  >
                    <Form.Label htmlFor="validationCustom02">
                      Last name*
                    </Form.Label>
                    <Form.Control
                      className={LastName_ER ? "form-error" : ""}
                      type="text"
                      id="validationCustom02"
                      placeholder="Last name*"
                      defaultValue="Doe"
                      value={LastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="validationCustomemail"
                    className="form-group"
                  >
                    <Form.Label htmlFor="validationCustom02">
                      Email Address*
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="email"
                        id="validationCustom02"
                        className={userEmail_ER ? "form-error" : ""}
                        placeholder="Your email"
                        aria-describedby="inputGroupPrepend"
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Wrong Email Address.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="validationCustomMobile"
                    className="form-group"
                  >
                    <Form.Label htmlFor="validationCustomMobile">
                      Mobile*
                    </Form.Label>
                    <PhoneInput
                      international
                      className={Mobile_ER ? "form-error" : ""}
                      defaultCountry="AU"
                      placeholder="Enter Mobile number"
                      value={newMobile}
                      onChange={setnewMobile}
                    />

                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="validationCustomCompany"
                    className="form-group"
                  >
                    <Form.Label htmlFor="validationCustomCompany">
                      Company
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="validationCustomCompany"
                      placeholder="Company"
                      value={Compamy}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="validationCustomPhone"
                    className="form-group"
                  >
                    <Form.Label htmlFor="validationCustomPhone">
                      Phone
                    </Form.Label>
                    <PhoneInput
                      international
                      defaultCountry="AU"
                      placeholder="Enter phone number"
                      value={newlnumber}
                      onChange={setnewlnumber}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="referan_code"
                    className="form-group"
                  >
                    <Form.Label htmlFor="referan_code">
                      Referral Code
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="referan_code"
                      placeholder="Referral code"
                      value={ReferalCode}
                      onChange={(e) => setReferalCode(e.target.value)}
                    />
                    <Form.Text className="" id="passwordHelpBlock3" muted>
                      Enter referral code that you received from your friend.
                    </Form.Text>

                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="formBasicCheckbox"
                    className="form-group"
                  >
                    <div className="form-check d-flex align-items-center">
                      <input
                        type="checkbox"
                        id="check_privacy"
                        className={
                          privacyPolicyER
                            ? "form-check-input"
                            : "form-check-input  form-error"
                        }
                        onChange={(e) => setPrivacyPolicy(!privacyPolicy)}
                      />
                      <label
                        title=""
                        htmlFor="check_privacy"
                        className="form-check-label"
                      >
                        I agree to the{" "}
                        <a
                          onClick={() => set_pp_show(true)}
                          className="text-primary "
                        >
                          Privacy Policy
                        </a>
                      </label>
                    </div>
                  </Form.Group>
                </>
              ) : (
                ""
              )}

              {ErrorMessage !== undefined ? (
                <Alert variant="danger">{ErrorMessage}</Alert>
              ) : (
                ""
              )}
              {SuccessMessage !== undefined ? (
                <Alert variant="success">{SuccessMessage}</Alert>
              ) : (
                ""
              )}
              {SomethingUpadating === true ? (
                <Alert variant="primary">Creating Account...</Alert>
              ) : (
                ""
              )}
              {SuccessMessage == undefined ? (
                <Form.Group as={Col} xs="12" className="form-group text-center">
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn-lg border-2 rounded-2"
                  >
                    Create Account
                  </Button>
                </Form.Group>
              ) : (
                ""
              )}
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <PrivacyPolicy show={pp_show} hide={HidePPPopup} />
    </>
  );
};
