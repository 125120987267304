import React, {useState, useEffect} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/react-hooks";

import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import "./App.css";

import {Header} from "./components/Header/Header";
import {Footer} from "./components/Footer/Footer";

import {ProductPage} from "./components/ProductListing/ProductPage";
import {HomePage} from "./components/DefaultPage/HomePage";

import {Attendees} from "./components/CheckoutProcess/Attendees";

import {Thankyou} from "./components/Thank-you/Thankyou";

import {SIngleProduct} from "./components/SingleProduct/SIngleProduct";

import {AdminMyEvents} from "./components/Admin/AdminMyEvents";
import {AdminOrders} from "./components/Admin/AdminOrders";
import {AdminCPDPoints} from "./components/Admin/AdminCPDPoints";
import {AdminMyLibrary} from "./components/Admin/AdminMyLibrary";
import {AdminProfile} from "./components/Admin/AdminProfile";
import {AdminWishlist} from "./components/Admin/AdminWishlist";
import {AdminRefer} from "./components/Admin/AdminRefer";

import {Login} from "./components/Modals/Login";
import {CreateAccount} from "./components/Modals/CreateAccount";

import {CartSidebar} from "./components/Modals/CartSidebar";

import {AboutPage} from "./components/Modals/AboutUs";

import {ForgotPassword} from "./components/Modals/ForgotPassword";
import {ForgotUsername} from "./components/Modals/ForgotUsername";

import ScrollToTop from "./components/ScrollToTop";

import {ActivateAccount} from "./components/activateAccount/ActivateAccount";
import {ResetPassword} from "./components/resetPassword/ResetPassword";

import CookieConsent from "react-cookie-consent";

import {Search} from "./components/Search/Search";
import {ActivateAccountGuest} from "./components/activateAccount/ActivateAccountGuest";
import {SignUp} from "./components/Modals/SignUp";
import {NotFound} from "./components/DefaultPage/notFound";
import {CanWeHelp} from "./components/Modals/CanWeHelp";
import {CookiePref} from "./components/Modals/CookiePref";
import {GetuserAddress} from "./components/grappql/Customers/Queries/GetuserAddress";
import {AddnewAddressQuery} from "./components/grappql/Customers/Mutations/customerAddressCreate";
import {customerAccessTokenCreate} from "./components/grappql/Customers/Mutations/customerAccessTokenCreate";

import {createCheckout} from "./components/grappql/Checkout/mutation/createCheckout";

import {checkoutLineItemsAdd} from "./components/grappql/Checkout/mutation/checkoutLineItemsAdd";

import {checkoutLineItemsUpdate} from "./components/grappql/Checkout/mutation/checkoutLineItemsUpdate";

import {checkoutLineItemsRemove} from "./components/grappql/Checkout/mutation/checkoutLineItemsRemove";

function App() {
  /***
   * Global Variables
   */
  const store_env = "live";

  const config = {
    staging: {
      EngineURL: "https://leo-engine.sfstaging.com.au/",
      AdminPanelURL: "https://phplaravel-1064140-3725363.cloudwaysapps.com/",
      storeURL: "https://leocussen-store.sfstaging.com.au/",
    },
    live: {
      EngineURL: "https://engine.leocussen.edu.au/",
      AdminPanelURL: "https://storeadmin.leocussen.edu.au/",
      storeURL: "https://store.leocussen.edu.au/",
    },
  };
  
  const { EngineURL, AdminPanelURL, storeURL } = config[store_env];
  
  /***
   * Producgram structure images
   */
  const Program_structure_images = [
    {
      "cpd-seminar":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Seminar.jpg",
    },
    {
      "cpd-seminar-(1-hour)":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Seminar.jpg",
    },
    {
      "cpd-seminar-(2-hour)":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Seminar.jpg",
    },
    {
      "cpd-online-module":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Online%20Module.jpg",
    },
    {
      "cpd-half-day-conference":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Conference.jpg",
    },
    {
      "online-intensive-(to-download)":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/Webcast%20Package.jpg",
    },
    {
      "cpd-workshop---essential":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Workshop.jpg",
    },
    {
      "cpd-intensive":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Intensive.jpg",
    },
    {
      "cpd-intensive-(3-point)":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Intensive.jpg",
    },
    {
      "cpd-intensive-(4-point)":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Intensive.jpg",
    },
    {
      "mental-health-first-aid-course":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/Mental%20Health%20First%20Aid.jpg",
    },
    {
      "online-intensive-(to-download)":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/Webcast%20Package.jpg",
    },
    {
      book: "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/Book.jpg",
    },
    {
      "corporate-division":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/Corporate%20Training%20Division.jpg",
    },
    {
      "practice-management-course":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/Practice%20Management%20Course.jpg",
    },
    {
      "traineeship-(swt)-workshop":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/SLT%20Supervised%20Legal%20Training.jpg",
    },
    {
      "traineeship-(slt)-1-day-workshop":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/SLT%20Supervised%20Legal%20Training.jpg",
    },
    {
      "traineeship-(slt)-1.5-day-workshop":
        "  https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/SLT%20Supervised%20Legal%20Training.jpg",
    },
    {
      "traineeship-(slt)-2-day-workshop":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/SLT%20Supervised%20Legal%20Training.jpg",
    },
    {
      "traineeship-(slt)-3-day-workshop":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/SLT%20Supervised%20Legal%20Training.jpg",
    },
    {
      "traineeship-(slt)-5-day-workshop":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/SLT%20Supervised%20Legal%20Training.jpg",
    },
    {
      "traineeship-(slt)-half-day-workshop":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/SLT%20Supervised%20Legal%20Training.jpg",
    },
    {
      "cpd-conference":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Conference.jpg",
    },
    {
      "cpd-full-day-conference":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Conference.jpg",
    },
    {
      "cpd-mini-conference":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Conference.jpg",
    },
    {
      "cpd-workshop---advocacy":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Workshop.jpg",
    },
    {
      "cpd-workshop---platinum":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Workshop.jpg",
    },
    {
      "legal-support-staff":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/Legal%20Support%20Staff.jpg",
    },
    {
      "medicare-billing-course":
        "https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/Medicare%20Billing%20Course.jpg",
    },
  ];
  /***
   * Producgram structure images
   */
  const Program_structure_color = [
    {"cpd-seminar": "#70c0c7"},
    {"cpd-seminar-(1-hour)": "#70c0c7"},
    {"cpd-seminar-(2-hour)": "#70c0c7"},
    {"cpd-online-module": "#84be9c"},
    {"cpd-half-day-conference": "#987C88"},
    {"online-intensive-(to-download)": "#8cafbd"},
    {"cpd-workshop---essential": "#fec010"},
    {"cpd-intensive": "#bc318c"},
    {"cpd-intensive-(3-point)": "#bc318c"},
    {"cpd-intensive-(4-point)": "#bc318c"},
    {"mental-health-first-aid-course": "#bc318c"},
    {"online-intensive-(to-download)": "#8cafbd"},
    {book: "#bc318c"},
    {"corporate-division": "#84be9c"},
    {"practice-management-course": "#987C88"},
    {"traineeship-(swt)-workshop": "#01293a"},
    {"traineeship-(slt)-1-day-workshop": "#01293a"},
    {"traineeship-(slt)-1.5-day-workshop": "#01293a"},
    {"traineeship-(slt)-2-day-workshop": "#01293a"},
    {"traineeship-(slt)-3-day-workshop": "#01293a"},
    {"traineeship-(slt)-5-day-workshop": "#01293a"},
    {"traineeship-(slt)-half-day-workshop": "#01293a"},
    {"cpd-conference": "#01293a"},
    {"cpd-full-day-conference": "#01293a"},
    {"cpd-mini-conference": "#01293a"},
    {"cpd-workshop---advocacy": "#fec010g"},
    {"cpd-workshop---platinum": "#fec010g"},
    {"legal-support-staff": "#bc318c"},
  ];

  if (window.location.pathname === "/thank-you") {
    localStorage.removeItem("checkout_id");
    localStorage.removeItem("cart_session");
    localStorage.removeItem("cart_session_sku");
  }

  const [Cartqty, setCartqty] = useState(0);
  const [CartAmount, setCartAmount] = useState(0.0);

  const UpdateCartQty = (val) => {
    setCartqty(val);
  };

  const UpdateCartAmount = (val) => {
    setCartAmount(val);
  };

  const [useLoggedIn, setUserLoggedIn] = useState(() => {
    const cart_item = localStorage.getItem("isLoggedin");
    return cart_item !== null && (cart_item === true || cart_item === "true")
      ? true
      : false;
  });

  const [offerBar, setofferBar] = useState(() => {
    const cart_item = localStorage.getItem("offerBar");
    return cart_item !== null && (cart_item === true || cart_item === "true")
      ? false
      : false;
  });

  const [offerBarTwo, setofferBarTwo] = useState(() => {
    const cart_item = localStorage.getItem("offerBarTwo");
    return cart_item !== null && (cart_item === true || cart_item === "true")
      ? false
      : false;
  });

  const UpdateDtatususeLoggedIn = () => {
    setUserLoggedIn(true);
    localStorage.setItem("isLoggedin", true);
  };

  const [LoginPopup, setLoginPopup] = useState(
    window.location.hash === "#login" ? true : false
  );
  /*  SHow Login Popup   */
  const HideLoginPopup = () => setLoginPopup(false);
  const ShowLoginPopup = () => setLoginPopup(true);

  const ShowLoginPopup_ = () => {
    if (useLoggedIn) {
      window.location.href = "/admin-profile";
    } else {
      setLoginPopup(true);
    }
  };

  const [lastURL, setlastURL] = useState(undefined);

  /*  SHow Login Popup   */

  const [CreateAccountPopup, setCreateAccountPopup] = useState(
    window.location.hash === "#register" ? true : false
  );
  /*  SHow Create Account Popup   */
  const HideCreateAccountPopup = () => setCreateAccountPopup(false);
  const ShowCreateAccountPopup = () => setCreateAccountPopup(true);

  const [CartSidebarPopup, setCartSidebarPopup] = useState(false);
  /*  SHow Create Account Popup   */
  const HideCartSidebarPopup = () => setCartSidebarPopup(false);
  const ShowCartSidebarPopup = () => setCartSidebarPopup(true);

  const [AboutPagePopup, setAboutPagePopup] = useState(false);
  /*  SHow Create Account Popup   */
  const HideAboutPagePopup = () => setAboutPagePopup(false);
  const ShowAboutPagePopup = () => setAboutPagePopup(true);

  const [SignUpPopup, setSignUpPopup] = useState(false);
  /*  SHow Create Account Popup   */
  const HideSignUpPopup = () => setSignUpPopup(false);
  const ShowSignUpPopup = () => setSignUpPopup(true);

  //cookie pref popup
  const [CookiePrefPopup, setCookiePrefPopup] = useState(() => {
    return localStorage.getItem("user_cookie") !== null ? false : true;
  });
  const HideCookiePrefPopup = () => {
    setCookiePrefPopup(false);
    localStorage.setItem("user_cookie", "enabled");
  };
  const ShowCookiePrefPopup = () => setCookiePrefPopup(true);

  /***
   * Forgot Passowrd
   */

  const [prodcut_list_order, set_prodcut_list_order] = useState(true);

  const [forgot_password_popup, setforgot_password_popup] = useState(false);
  const [forgot_username_popup, setforgot_username_popup] = useState(false);

  const forgot_password_popup_hide = () => setforgot_password_popup(false);
  const forgot_password_popup_show = () => setforgot_password_popup(true);

  const forgot_username_popup_hide = () => setforgot_username_popup(false);
  const forgot_username_popup_show = () => setforgot_username_popup(true);

  const redirectTocart = () => {
    ShowCartSidebarPopup();
  };

  const OpenRegisterPopupFromLogin = () => {
    setLoginPopup(false);
    setCreateAccountPopup(true);
  };
  /* */
  const [userEmailPass, setLoginuserDetail] = useState({
    email: "",
    password: "",
  });
  const [UpdateloginUserDetail, setUpdateloginUserDetail] = useState(() => {
    const localdetail = localStorage.getItem("userDetail");
    return localdetail !== null ? JSON.parse(localdetail) : undefined;
  });

  const [LoginErrorMsgm, setLoginErrorMessage] = useState(undefined);
  const [requested_for, set_requested_for] = useState(undefined);

  const [redirect_url_, set_redirect_url_] = useState("/");

  const DoLoginAuth = (
    useremail,
    password,
    remember_me,
    requested_for,
    redirect_url
  ) => {
    set_requested_for(requested_for);
    set_redirect_url_(redirect_url);
    const input = {
      email: useremail,
      password: password,
    };
    setLoginuserDetail(input);
    setTimeout(function () {
      VerifyLogin();
    }, 10);
  };
  useEffect(() => {
    if (
      UpdateloginUserDetail !== undefined &&
      UpdateloginUserDetail !== null &&
      UpdateloginUserDetail.hasOwnProperty("accessToken") &&
      UpdateloginUserDetail.accessToken !== "" &&
      UpdateloginUserDetail.accessToken !== null
    ) {
      if (program_date_is_gone_or_not(UpdateloginUserDetail.expireeAt)) {
        localStorage.removeItem("userDetail");
        localStorage.setItem("isLoggedin", false);
        localStorage.removeItem("checkout_token");
        localStorage.removeItem("thank_you_image");
        localStorage.removeItem("checkout_id");
        localStorage.removeItem("cart_session");
        localStorage.removeItem("create_account_detail");
        localStorage.removeItem("cart_session_sku");
        localStorage.removeItem("isLoggedin");
        localStorage.removeItem("was_book");
        localStorage.removeItem("wishlist_item");
        localStorage.removeItem("new_practice_area");
        window.location.href = "/";
      } else {
        setUserLoggedIn(true);
        localStorage.setItem("isLoggedin", true);
      }
    }
  }, []);

  const [VerifyLogin, {loading, data, error}] = useMutation(
    customerAccessTokenCreate,
    {
      variables: {userEmailPass},
    }
  );

  const [
    doAddAddress,
    {
      data: address_added,
      error: error_on_address_added,
      loading: address_adding_new,
    },
  ] = useMutation(AddnewAddressQuery);

  useEffect(() => {
    if (data != undefined) {
      if (data.customerAccessTokenCreate.customerAccessToken != null) {
        const SaveDetail = {
          email: userEmailPass.email,
          token: userEmailPass.password,
          accessToken:
            data.customerAccessTokenCreate.customerAccessToken.accessToken,
          expireeAt:
            data.customerAccessTokenCreate.customerAccessToken.expiresAt,
          rememberMe: userEmailPass.rememberMe,
        };
        localStorage.setItem("userDetail", JSON.stringify(SaveDetail));
        setUpdateloginUserDetail(SaveDetail);
        //create user detail as address
        if (requested_for == "Register") {
          const register_user_detail = localStorage.getItem(
            "create_account_detail"
          );
          if (register_user_detail != null) {
            const register_user_detail_json = JSON.parse(register_user_detail);
            //  console.log(register_user_detail_json);
            doAddAddress({
              variables: {
                customerAccessToken:
                  data.customerAccessTokenCreate.customerAccessToken
                    .accessToken,
                address: {
                  lastName: register_user_detail_json.lastName,
                  firstName: register_user_detail_json.firstName,
                  phone: register_user_detail_json.phone,
                  company: register_user_detail_json.company,
                },
              },
            });
            UpdateDtatususeLoggedIn(true);
          } else {
            UpdateDtatususeLoggedIn(true);

            window.location.href = redirect_url_;
          }
        } else {
          UpdateDtatususeLoggedIn(true);

          window.location.href =
            redirect_url_ === "/activate" || redirect_url_ === "/activate-guest"
              ? "/"
              : redirect_url_;
        }
      } else {
        setLoginErrorMessage(undefined);
        data.customerAccessTokenCreate.customerUserErrors.forEach(function (
          error
        ) {
          // console.log(error);
          setLoginErrorMessage("Email/Mobile or Password is not correct!");
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (address_added != undefined) {
      //  console.log(address_added);
      window.location.href = redirect_url_;
    }
  }, [address_added]);

  /**
   * Fetch data
   */

  const [CheckoutID, setCheckoutID] = useState(() => {
    const cart_item = localStorage.getItem("checkout_id");
    const cart_item_json = JSON.parse(cart_item);
    if (cart_item !== null && cart_item_json.userErrors.length !== 0) {
      localStorage.removeItem("userDetail");
      localStorage.setItem("isLoggedin", false);
      localStorage.removeItem("checkout_token");
      localStorage.removeItem("thank_you_image");
      localStorage.removeItem("checkout_id");
      localStorage.removeItem("cart_session");
      localStorage.removeItem("create_account_detail");
      localStorage.removeItem("cart_session_sku");
      localStorage.removeItem("isLoggedin");
      localStorage.removeItem("was_book");
      window.location.href = "/";
      return undefined;
    } else {
      return cart_item !== null && cart_item_json.userErrors.length === 0
        ? cart_item_json.cart.id
        : undefined;
    }
  });

  const [InitialLoad, setInitialLoad] = useState(true);
  const [CardItem, AdditemTOcart] = useState(() => {
    const cart_item = localStorage.getItem("cart_session");
    return cart_item !== null ? JSON.parse(cart_item) : {lines: []};
  });

  const [CardItem_sku, AdditemTOcart_sku] = useState(() => {
    const cart_item = localStorage.getItem("cart_session_sku");
    return cart_item !== null ? JSON.parse(cart_item) : {lines: []};
  });

  const [CheckoutData, setCheckout] = useState(() => {
    const cart_item = localStorage.getItem("checkout_id");
    return cart_item !== null ? JSON.parse(cart_item) : undefined;
  });

  const getCheckoutID = () => {
    const cart_item = localStorage.getItem("checkout_id");
    const cart_item_json = JSON.parse(cart_item);
    return cart_item !== null ? cart_item_json.cart.id : undefined;
  };

  const getCardItem_sku = () => {
    const cart_item = localStorage.getItem("cart_session_sku");
    return cart_item !== null ? JSON.parse(cart_item) : {lines: []};
  };

  const getCardItem = () => {
    const cart_item = localStorage.getItem("cart_session");
    return cart_item !== null ? JSON.parse(cart_item) : {lines: []};
  };

  const getCheckoutData = () => {
    const cart_item = localStorage.getItem("checkout_id");
    return cart_item !== null ? JSON.parse(cart_item) : undefined;
  };

  useEffect(() => {
    console.log(CheckoutData);
    if (CheckoutData != undefined) {
      UpdateCartAmount(CheckoutData.cart.cost.totalAmount);
      let prc = 0;
      CheckoutData.cart.lines.edges.map((item) => {
        prc += item.node.quantity;
      });
      UpdateCartQty(prc);
    }
  }, [CheckoutData]);

  /***
   * Add item to cart query
   */
  const [
    CreateCheckoutQL,
    {loading: Addtocartloading, data: Addtocartdata, error: Addtocarterror},
  ] = useMutation(createCheckout, {
    variables: {CardItem},
  });

  /***
   * Add more product on existing Checkout
   */
  const [
    AddmoreItemInCar,
    {
      loading: AddtocartMoreloading,
      data: AddtocartMoredata,
      error: AddtocartMoreerror,
    },
  ] = useMutation(checkoutLineItemsAdd);

  /****
   * Update cart quanitty
   */
  const [
    UpdateCartItemQuery,
    {loading: UpdateItemLoading, data: UpdatedData, error: ErrorUpdateCart},
  ] = useMutation(checkoutLineItemsUpdate);

  /***
   * Remove Cart quntity
   */
  const [
    RemoveCartItemQuery,
    {loading: RemoveItemLoading, data: RemovedData, error: RemoveUpdateCart},
  ] = useMutation(checkoutLineItemsRemove);

  /***
   * Add to cart (called by add to card button)
   */
  const AddtoCart = (
    merchandiseId,
    Quantity,
    sku,
    stream_type,
    record_type,
    tags,
    pimage,
    slug,
    start_date,
    program_structure
  ) => {
    setInitialLoad(false);

    const newItem = {merchandiseId, quantity: Quantity};

    const newItemSku = {
      merchandiseId,
      quantity: Quantity,
      sku,
      stream_type,
      record_type,
      tags,
      pimage,
      slug,
      start_date,
      program_structure,
    };

    let CartItem = getCardItem();
    let CartItemSku = getCardItem_sku();
    let updatedCartSku = [];

    console.log("Current CartItemSku:", CartItemSku);

    if (CartItemSku.lines.length < 1) {
      updatedCartSku = [newItemSku];
    } else {
      let itemExists = false;

      updatedCartSku = CartItemSku.lines.map((cartdata) =>
        cartdata.merchandiseId === merchandiseId
          ? ((itemExists = true),
            {...cartdata, quantity: cartdata.quantity + Quantity})
          : cartdata
      );

      if (!itemExists) updatedCartSku.push(newItemSku);
    }

    console.log("Updated Cart SKU:", updatedCartSku);

    const mylineitem = {lines: [newItem]};
    const mylineitem_sku = {lines: updatedCartSku};

    redirectTocart();

    AdditemTOcart(mylineitem);
    localStorage.setItem("cart_session", JSON.stringify(mylineitem));

    AdditemTOcart_sku(mylineitem_sku);
    localStorage.setItem("cart_session_sku", JSON.stringify(mylineitem_sku));
  };

  /**
   *  Call API to add product in cart
   */
  useEffect(() => {
    if (!InitialLoad) {
      let CheckoutData = getCheckoutData();

      if (CheckoutData != undefined) {
        if (CheckoutData.cart.lines.edges.length < 1) {
          CreateCheckoutQL();
        } else {
          console.log({
            variables: {
              checkoutId: CheckoutData.cart.id,
              lines: CardItem.lines,
            },
          });
          AddmoreItemInCar({
            variables: {
              checkoutId: CheckoutData.cart.id,
              lines: CardItem.lines,
            },
          });
        }
      } else if (CardItem.lines.length) {
        CreateCheckoutQL();
      }
      setInitialLoad(false);
    }
  }, [CardItem]);

  /***
   * Cart API response
   */
  useEffect(() => {
    if (Addtocartdata != undefined) {
      setCheckout(Addtocartdata.cartCreate);
      localStorage.setItem(
        "checkout_id",
        JSON.stringify(Addtocartdata.cartCreate)
      );
      setCheckoutID(Addtocartdata.cartCreate.cart.id);
    }
  }, [Addtocartdata]);

  useEffect(() => {
    if (AddtocartMoredata != undefined) {
      setCheckout(AddtocartMoredata.cartLinesAdd);
      localStorage.setItem(
        "checkout_id",
        JSON.stringify(AddtocartMoredata.cartLinesAdd)
      );
    }
  }, [AddtocartMoredata]);

  function removeItemfromCart(qty, lineID) {
    let CheckoutID = getCheckoutID();
    const variables = {checkoutId: CheckoutID, lineItemIds: [lineID]};
    RemoveCartItemQuery({variables});

    var sku = [];
    var cart_item_sku = CardItem_sku.lines.filter(function (itm) {
      // console.log(itm.merchandiseId + "!=" + lineID)
      return itm.merchandiseId != lineID;
    });

    //  console.log(cart_item_sku);

    // AdditemTOcart_sku(cart_item_sku);
  }

  useEffect(() => {
    if (RemovedData != undefined) {
      setCheckout(RemovedData.cartLinesRemove);
      localStorage.setItem(
        "checkout_id",
        JSON.stringify(RemovedData.cartLinesRemove)
      );

      if (window.location.pathname == "/attendees") {
        window.location.reload();
      }
    }
  }, [RemovedData]);

  function UpdateCartItem(qty, lineID) {
    let CheckoutID = getCheckoutID();

    // console.log("Updated qty")
    const TempLineITEM = [];
    TempLineITEM.push({id: lineID, quantity: parseInt(qty)});
    // console.log("CheckoutID ->" + CheckoutID);

    UpdateCartItemQuery({
      variables: {
        checkoutId: CheckoutID,
        lines: TempLineITEM,
      },
    });

    if (window.location.pathname == "/attendees") {
      // window.location.reload();
    }
  }

  useEffect(() => {
    if (UpdatedData != undefined) {
      setCheckout(UpdatedData.cartLinesUpdate);
      // console.log(UpdatedData);
      localStorage.setItem(
        "checkout_id",
        JSON.stringify(UpdatedData.cartLinesUpdate)
      );
      if (window.location.pathname == "/attendees") {
        window.location.reload();
      }
    }
  }, [UpdatedData]);

  const customerAccessToken =
    UpdateloginUserDetail != undefined
      ? UpdateloginUserDetail.accessToken
      : undefined;

  const {
    loading: getting_address,
    error: address_error,
    data: UserData,
  } = useQuery(GetuserAddress, {
    variables: {customerAccessToken},
    skip: UpdateloginUserDetail === undefined ? true : false,
  });

  /****
   * function calling from attendees page
   */
  function Logged_In_user_associative_with_checkout() {}

  const [pageJson, setpageJson] = useState([]);

  /*****
   * Store page
   *
   */
  //let ProductPageJsonTemp = [];
  const randomString = (Math.random() + 1).toString(36).substring(7);
  const [ProductPageJson, setProductPageJson] = useState([]);
  useEffect(() => {
    if (ProductPageJson.length === 0) {
      console.count("pagejson");
      axios
        .get(
          EngineURL +
            "sync/update-page-item/store-page-data.json?var=" +
            randomString
        )
        .then(function (response) {
          //  console.log(response.data);
          setProductPageJson(response.data);
        });
    }
  }, []);

  useEffect(() => {
    setpageJson(
      ProductPageJson.sort(function (a, b) {
        return a.Menu_Order__c - b.Menu_Order__c;
      })
    );
    // console.log(ProductPageJson);
  }, [ProductPageJson]);

  // groupValue === Shop in first

  /****
   * Store page
   *
   */

  /*****
   * HEader search function
   */

  const [HeaderSearchText, setHeaderSearchText] = useState("");

  const program_date_is_gone_or_not = (program_start_date_time) => {
    if (program_start_date_time === null) {
      return false;
    }
    program_start_date_time = new Date(program_start_date_time);
    let program_time_modify = program_start_date_time.setHours(
      program_start_date_time.getHours()
    );
    let current_time = new Date().getTime();
    if (parseFloat(program_time_modify) - parseFloat(current_time) > 0) {
      return false;
    } else {
      return true;
    }
  };

 

  /*****
   * Can we help
   */
  const [CanWeHelpStatus, setCanWeHelpStatus] = useState(false);

  /***
   * Most popular search tag
   */
  const [PopularTag, setPopularTag] = useState([]);
  const fetchTag = async () => {
    try {
      const home_page_filters = AdminPanelURL + "api/home_page_filters";
      const home_page_filters_response = await axios(home_page_filters);
      // console.log(api_URL);

      if (home_page_filters_response.status === 200) {
        var data = home_page_filters_response.data;
        if (data.length !== 0) {
          // console.log(data);
          setPopularTag(data.filters);
        }
      }
    } catch (error) {
      console.log(true);
    }
  };
  useEffect(() => {
    fetchTag();
  }, []);

  /***
   * Add to Wishlist
   * Phase 2
   */

  const AddtoWishlist = (productid, pdates) => {
    let wishlistItem =
      localStorage.getItem("wishlist_item") !== undefined &&
      localStorage.getItem("wishlist_item") !== "undefined" &&
      localStorage.getItem("wishlist_item") !== null
        ? JSON.parse(localStorage.getItem("wishlist_item"))
        : [];
    let wishlistItemArray =
      localStorage.getItem("wishlist_item_array") !== null
        ? JSON.parse(localStorage.getItem("wishlist_item_array"))
        : [];

    let NewwishlistItem = [];
    let NewwishlistItemArray = [];
    if (!wishlistItem.includes(productid)) {
      NewwishlistItem.push(productid);
      if (pdates !== null) {
        var dt = new Date(pdates);

        var year = dt.getFullYear();
        var month = (dt.getMonth() + 1).toString().padStart(2, "0");
        var day = dt.getDate().toString().padStart(2, "0");
      }
      NewwishlistItemArray.push({
        handle: productid,
        date: pdates !== null ? year + "-" + month + "-" + day : null,
      });

      //Save to database throug API
      if (useLoggedIn) {
        let userDetail = JSON.parse(localStorage.getItem("userDetail"));

        let pslug = [];
        let pdate = [];
        NewwishlistItemArray.map((prdct) => {
          pslug.push(prdct.handle);
          pdate.push(prdct.date);
        });

        const Wishlistdata = {
          email: userDetail.email,
          p_id: pslug.join(","),
          date: pdate.join(","),
        };
        axios
          .post(EngineURL + "wishlist/wishlist_add.php", Wishlistdata)
          .then(function (response) {
            //console.log(response);
          })
          .catch(function (error) {
            // console.log(error);
          });
      }
    } else {
      //Remove item from wishlist
      RemoveItemWhishlst(productid);
    }

    wishlistItem.map((data) => {
      if (data !== productid) {
        NewwishlistItem.push(data);
      }
    });

    setgetAllWishlist(NewwishlistItem);
    localStorage.setItem("wishlist_item", JSON.stringify(NewwishlistItem));
    localStorage.setItem(
      "wishlist_item_array",
      JSON.stringify(NewwishlistItemArray)
    );
  };

  /****
   * Remove item from wishlist
   * Phase 2
   */
  const RemoveItemWhishlst = (productid) => {
    if (useLoggedIn) {
      let userDetail = JSON.parse(localStorage.getItem("userDetail"));
      let Wishlistdata = {
        email: userDetail.email,
        p_id: productid,
      };

      axios
        .post(EngineURL + "wishlist/wishlist_delete.php", Wishlistdata)
        .then(function (response) {
          //console.log(response);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  };

  /***
   * Save offline data to databse
   * Phase 2
   */
  useEffect(() => {
    let wishlistItem =
      localStorage.getItem("wishlist_item") !== undefined &&
      localStorage.getItem("wishlist_item") !== "undefined" &&
      localStorage.getItem("wishlist_item") !== null
        ? JSON.parse(localStorage.getItem("wishlist_item"))
        : [];
    //Save to database throug API
    //After login
    if (useLoggedIn && wishlistItem.length !== 0) {
      let userDetail = JSON.parse(localStorage.getItem("userDetail"));

      const Wishlistdata = {
        email: userDetail.email,
        p_id: wishlistItem.join(","),
      };
      axios
        .post(EngineURL + "wishlist/wishlist_add.php", Wishlistdata)
        .then(function (response) {
          // console.log(response);
          FetchWishlistData();
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }, []);

  /***
   * Get user Wishlist & Save without login wishlist to Database
   * Phase 2
   */
  const [getAllWishlist, setgetAllWishlist] = useState([]);
  const FetchWishlistData = async () => {
    let userDetail = JSON.parse(localStorage.getItem("userDetail"));

    axios
      .get(EngineURL + "wishlist/wishlist_get.php?email=" + userDetail.email)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.length !== 0) {
            setgetAllWishlist(response.data.result);
            localStorage.setItem(
              "wishlist_item",
              JSON.stringify(response.data.result)
            );
          }
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  useEffect(() => {
    if (useLoggedIn) {
      FetchWishlistData();
    } else {
      //load wishlist from localstorage
      let wishlistItem =
        localStorage.getItem("wishlist_item") !== undefined &&
        localStorage.getItem("wishlist_item") !== "undefined" &&
        localStorage.getItem("wishlist_item") !== null
          ? JSON.parse(localStorage.getItem("wishlist_item"))
          : [];
      if (wishlistItem.length !== 0) {
        setgetAllWishlist(wishlistItem);
      }
    }
  }, []);

  const isDayIS = () => {
    var today = new Date();
    var curHr = today.getHours();

    if (curHr < 12) {
      return "Good morning";
    } else if (curHr < 18) {
      return "Good afternoon";
    } else {
      return "Good evening";
    }
  };
  const Good = isDayIS();

  return (
    <>
      <Router>
        <Header
          setHeaderSearchText={setHeaderSearchText}
          offerBar={offerBar}
          setofferBar={setofferBar}
          ProductPageJson={pageJson}
          redirectTocart={redirectTocart}
          ShowAboutPagePopup={ShowAboutPagePopup}
          ShowLoginPopup={ShowLoginPopup}
          useLoggedIn={useLoggedIn}
          ShowLoginPopup_={ShowLoginPopup_}
          CartAmount={CartAmount}
          ShowCreateAccountPopup={ShowCreateAccountPopup}
          AdminPanelURL={AdminPanelURL}
          ShowSignUpPopup={ShowSignUpPopup}
          Cartqty={Cartqty}
        />
        <ScrollToTop />
       
        <div className="main position-relative">
          <Switch>
            <Route exact path="/">
              <HomePage
                offerBarTwo={offerBarTwo}
                setofferBarTwo={setofferBarTwo}
                lastURL={lastURL}
                setlastURL={setlastURL}
                ShowLoginPopup={ShowLoginPopup}
                useLoggedIn={useLoggedIn}
                Program_structure_images={Program_structure_images}
                EngineURL={EngineURL}
                prodcut_list_order={prodcut_list_order}
                set_prodcut_list_order={set_prodcut_list_order}
                ProductPageJson={ProductPageJson}
                AddtoCart={AddtoCart}
                UpdateCartQty={UpdateCartQty}
                ShowSignUpPopup={ShowSignUpPopup}
                UserData={UserData}
                AdminPanelURL={AdminPanelURL}
                PopularTag={PopularTag}
                UpdateCartAmount={UpdateCartAmount}
              />
            </Route>
            <Route path="/product/:slug">
              <SIngleProduct
                AddtoWishlist={AddtoWishlist}
                getAllWishlist={getAllWishlist}
                EngineURL={EngineURL}
                Program_structure_images={Program_structure_images}
                Program_structure_color={Program_structure_color}
                AddtoCart={AddtoCart}
                UpdateCartQty={UpdateCartQty}
                UpdateCartAmount={UpdateCartAmount}
              />
            </Route>

            {ProductPageJson.map((menu, index) => {
              const menuname = menu.Name;
              if (
                menuname === null ||
                menuname === undefined ||
                menu.Status !== "Active"
              )
                return;
              const slug = menuname.split(" ").join("-").toLowerCase();
              return (
                <Route path={"/" + slug} key={menuname}>
                  <ProductPage
                    key={menuname + "111"}
                    AddtoWishlist={AddtoWishlist}
                    getAllWishlist={getAllWishlist}
                    PopularTag={PopularTag}
                    ShowSignUpPopup={ShowSignUpPopup}
                    offerBarTwo={offerBarTwo}
                    setofferBarTwo={setofferBarTwo}
                    lastURL={lastURL}
                    setlastURL={setlastURL}
                    EngineURL={EngineURL}
                    Program_structure_images={Program_structure_images}
                    prodcut_list_order={prodcut_list_order}
                    set_prodcut_list_order={set_prodcut_list_order}
                    ProductPageJson={ProductPageJson}
                    AddtoCart={AddtoCart}
                    UpdateCartQty={UpdateCartQty}
                    ShowLoginPopup={ShowLoginPopup}
                    useLoggedIn={useLoggedIn}
                    UserData={UserData}
                    AdminPanelURL={AdminPanelURL}
                    Program_structure_color={Program_structure_color}
                    storeURL={storeURL}
                    UpdateCartAmount={UpdateCartAmount}
                  />
                </Route>
              );
            })}

            <Router path="/search">
              <Search
                AddtoWishlist={AddtoWishlist}
                getAllWishlist={getAllWishlist}
                Program_structure_color={Program_structure_color}
                PopularTag={PopularTag}
                ShowSignUpPopup={ShowSignUpPopup}
                offerBarTwo={offerBarTwo}
                setofferBarTwo={setofferBarTwo}
                lastURL={lastURL}
                setlastURL={setlastURL}
                EngineURL={EngineURL}
                Program_structure_images={Program_structure_images}
                prodcut_list_order={prodcut_list_order}
                set_prodcut_list_order={set_prodcut_list_order}
                ProductPageJson={ProductPageJson}
                AddtoCart={AddtoCart}
                UpdateCartQty={UpdateCartQty}
                ShowLoginPopup={ShowLoginPopup}
                useLoggedIn={useLoggedIn}
                UserData={UserData}
                AdminPanelURL={AdminPanelURL}
                storeURL={storeURL}
                UpdateCartAmount={UpdateCartAmount}
              />
            </Router>

            <Router path="/all-courses">
              <Search
                AddtoWishlist={AddtoWishlist}
                getAllWishlist={getAllWishlist}
                Program_structure_color={Program_structure_color}
                PopularTag={PopularTag}
                ShowSignUpPopup={ShowSignUpPopup}
                offerBarTwo={offerBarTwo}
                setofferBarTwo={setofferBarTwo}
                lastURL={lastURL}
                setlastURL={setlastURL}
                EngineURL={EngineURL}
                Program_structure_images={Program_structure_images}
                prodcut_list_order={prodcut_list_order}
                set_prodcut_list_order={set_prodcut_list_order}
                ProductPageJson={ProductPageJson}
                AddtoCart={AddtoCart}
                UpdateCartQty={UpdateCartQty}
                ShowLoginPopup={ShowLoginPopup}
                useLoggedIn={useLoggedIn}
                UserData={UserData}
                AdminPanelURL={AdminPanelURL}
                storeURL={storeURL}
                UpdateCartAmount={UpdateCartAmount}
              />
            </Router>

            <Route path="/my-wishlist">
              <AdminWishlist
                Good={Good}
                AddtoWishlist={AddtoWishlist}
                getAllWishlist={getAllWishlist}
                EngineURL={EngineURL}
                useLoggedIn={useLoggedIn}
                Program_structure_images={Program_structure_images}
                Program_structure_color={Program_structure_color}
                UpdateloginUserDetail={UpdateloginUserDetail}
                UpdateCartQty={UpdateCartQty}
                AddtoCart={AddtoCart}
                UpdateCartAmount={UpdateCartAmount}
                ShowLoginPopup={ShowLoginPopup}
                ShowLoginPopup_={ShowLoginPopup_}
                UserData={UserData}
              />
            </Route>
            <Route path="/admin-my-events">
              <AdminMyEvents
                Good={Good}
                EngineURL={EngineURL}
                useLoggedIn={useLoggedIn}
                UpdateloginUserDetail={UpdateloginUserDetail}
                UpdateCartQty={UpdateCartQty}
                UpdateCartAmount={UpdateCartAmount}
                ShowLoginPopup={ShowLoginPopup}
                ShowLoginPopup_={ShowLoginPopup_}
                UserData={UserData}
              />
            </Route>
            <Route path="/admin-orders">
              <AdminOrders
                Good={Good}
                EngineURL={EngineURL}
                useLoggedIn={useLoggedIn}
                UpdateloginUserDetail={UpdateloginUserDetail}
                UpdateCartQty={UpdateCartQty}
                UpdateCartAmount={UpdateCartAmount}
                ShowLoginPopup={ShowLoginPopup}
                ShowLoginPopup_={ShowLoginPopup_}
                UserData={UserData}
              />
            </Route>

            <Route path="/admin-cpd-points">
              <AdminCPDPoints
                Good={Good}
                EngineURL={EngineURL}
                useLoggedIn={useLoggedIn}
                UpdateloginUserDetail={UpdateloginUserDetail}
                UpdateCartQty={UpdateCartQty}
                UpdateCartAmount={UpdateCartAmount}
                ShowLoginPopup={ShowLoginPopup}
                ShowLoginPopup_={ShowLoginPopup_}
                UserData={UserData}
                AdminPanelURL={AdminPanelURL}
              />
            </Route>
            <Route path="/admin-my-library">
              <AdminMyLibrary
                Good={Good}
                EngineURL={EngineURL}
                Program_structure_images={Program_structure_images}
                useLoggedIn={useLoggedIn}
                UpdateloginUserDetail={UpdateloginUserDetail}
                UpdateCartQty={UpdateCartQty}
                UpdateCartAmount={UpdateCartAmount}
                ShowLoginPopup={ShowLoginPopup}
                ShowLoginPopup_={ShowLoginPopup_}
                UserData={UserData}
              />
            </Route>
            <Route path="/refer-earn">
              <AdminRefer
                Good={Good}
                EngineURL={EngineURL}
                useLoggedIn={useLoggedIn}
                UserData={UserData}
                UpdateloginUserDetail={UpdateloginUserDetail}
              />
            </Route>
            <Route path="/reset">
              <ResetPassword ShowLoginPopup={ShowLoginPopup} />
            </Route>
            <Route path="/activate">
              <ActivateAccount
                EngineURL={EngineURL}
                ShowLoginPopup={ShowLoginPopup}
              />
            </Route>
            <Route path="/activate-guest">
              <ActivateAccountGuest
                EngineURL={EngineURL}
                ShowLoginPopup={ShowLoginPopup}
              />
            </Route>
            <Route path="/admin-profile">
              <AdminProfile
                Good={Good}
                UpdateloginUserDetail={UpdateloginUserDetail}
                useLoggedIn={useLoggedIn}
                ShowLoginPopup_={ShowLoginPopup}
                UpdateCartQty={UpdateCartQty}
                EngineURL={EngineURL}
                UpdateCartAmount={UpdateCartAmount}
              />
            </Route>

            <Route path="/attendees">
              <Attendees
                EngineURL={EngineURL}
                Program_structure_images={Program_structure_images}
                ShowLoginPopup={ShowLoginPopup}
                CardItem_sku={CardItem_sku}
                UserData={UserData}
                useLoggedIn={useLoggedIn}
                CartData={CheckoutData}
                customerAccessToken={customerAccessToken}
                ShowCreateAccountPopup={ShowCreateAccountPopup}
                HideCreateAccountPopup={HideCreateAccountPopup}
                setAddresstoCheckout={Logged_In_user_associative_with_checkout}
                UpdateCartQty={UpdateCartQty}
                UpdateCartAmount={UpdateCartAmount}
              />
            </Route>

            <Route path="/thank-you">
              <Thankyou
                EngineURL={EngineURL}
                ShowCreateAccountPopup={ShowCreateAccountPopup}
                ShowLoginPopup={ShowLoginPopup}
                Program_structure_images={Program_structure_images}
                useLoggedIn={useLoggedIn}
                UpdateCartQty={UpdateCartQty}
                UpdateCartAmount={UpdateCartAmount}
              />
            </Route>
            <Route path="*" component={NotFound} status={404} />
          </Switch>
        </div>
        <CookieConsent
          location="bottom"
          buttonText="Accept!"
          cookieName="myAwesomeCookieName2"
          style={{background: "#01293A"}}
          buttonStyle={{
            background: "#FEC010",
            color: "#01293A",
            fontSize: "14px",
          }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>

        <Footer EngineURL={EngineURL} setCanWeHelpStatus={setCanWeHelpStatus} />

        <Login
          forgot_password_popup_show={forgot_password_popup_show}
          OpenRegisterPopupFromLogin={OpenRegisterPopupFromLogin}
          forgot_username_popup_show={forgot_username_popup_show}
          forgot_username_popup_hide={forgot_username_popup_hide}
          LoginPopup={LoginPopup}
          HideLoginPopup={HideLoginPopup}
          EngineURL={EngineURL}
          DoLoginAuth={DoLoginAuth}
          setLoginErrorMessage={setLoginErrorMessage}
          LoginErrorMsgm={LoginErrorMsgm}
          useLoggedIn={useLoggedIn}
        />

        <CreateAccount
          EngineURL={EngineURL}
          DoLoginAuth={DoLoginAuth}
          UpdateDtatususeLoggedIn={UpdateDtatususeLoggedIn}
          CreateAccountPopup={CreateAccountPopup}
          ShowCreateAccountPopup={ShowCreateAccountPopup}
          HideCreateAccountPopup={HideCreateAccountPopup}
        />

        <CartSidebar
          UpdateCartItem={UpdateCartItem}
          removeItemfromCart={removeItemfromCart}
          CartData={CheckoutData}
          CardItem_sku={CardItem_sku}
          Addtocartloading={Addtocartloading}
          AddtocartMoreloading={AddtocartMoreloading}
          RemoveItemLoading={RemoveItemLoading}
          UpdateItemLoading={UpdateItemLoading}
          CartSidebarPopup={CartSidebarPopup}
          HideCartSidebarPopup={HideCartSidebarPopup}
        />

        <AboutPage
          AboutPagePopup={AboutPagePopup}
          ShowAboutPagePopup={ShowAboutPagePopup}
          HideAboutPagePopup={HideAboutPagePopup}
        />

        <SignUp
          EngineURL={EngineURL}
          SignUpPopup={SignUpPopup}
          ShowSignUpPopup={ShowSignUpPopup}
          HideSignUpPopup={HideSignUpPopup}
        />

        <ForgotPassword
          LoginPopup={LoginPopup}
          ShowLoginPopup={ShowLoginPopup}
          HideLoginPopup={HideLoginPopup}
          forgot_password_popup={forgot_password_popup}
          forgot_password_popup_show={forgot_password_popup_show}
          forgot_password_popup_hide={forgot_password_popup_hide}
          forgot_username_popup={forgot_username_popup}
          forgot_username_popup_show={forgot_username_popup_show}
          forgot_username_popup_hide={forgot_username_popup_hide}
        />

        <ForgotUsername
          LoginPopup={LoginPopup}
          ShowLoginPopup={ShowLoginPopup}
          HideLoginPopup={HideLoginPopup}
          EngineURL={EngineURL}
          forgot_username_popup={forgot_username_popup}
          forgot_username_popup_show={forgot_username_popup_show}
          forgot_username_popup_hide={forgot_username_popup_hide}
        />

        <CanWeHelp
          EngineURL={EngineURL}
          CanWeHelpStatus={CanWeHelpStatus}
          setCanWeHelpStatus={setCanWeHelpStatus}
        />
        <CookiePref
          CookiePrefPopup={CookiePrefPopup}
          HideCookiePrefPopup={HideCookiePrefPopup}
          ShowCookiePrefPopup={ShowCookiePrefPopup}
        />
      </Router>
    </>
  );
}

export default App;
