import React, { useState, useEffect } from 'react'
import {
    // Card,
    Button,
    Modal,
    Container,
    Row,
    Col,
    Alert,
    // ModalBody,
    Form
} from 'react-bootstrap';



import { useMutation } from '@apollo/react-hooks';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import axios from 'axios';
import { customerActivateByUrl } from '../grappql/Customers/Mutations/customerActivateByUrl';


  
  
  
export const ActivateAccountGuest = (props) => {
    const location = useLocation();

    const queryParam = queryString.parse(location.search);

    if (queryParam["activationUrl"] === undefined) {
        window.location.href = "/";
    }

    const ResetUrl = queryParam["activationUrl"] !== undefined && queryParam["activationUrl"] !== null ? queryParam["activationUrl"] : undefined;
    const userid = queryParam["userid"] !== undefined && queryParam["userid"] !== null ? queryParam["userid"] : undefined;
    const userEmail = queryParam["email"] !== undefined && queryParam["email"] !== null ? queryParam["email"] : undefined;

    const [user_password, set_user_password] = useState("");
    const [conf_password, set_conf_password] = useState("");

    const [ErrorMessage, setErrorMessage] = useState(undefined);
    const [SuccessMessage, setSuccessMessage] = useState(undefined);
    const [SomethingUpadating, setSomethingUpadating] = useState(false);


    const [ResetPassword, {
        data: recovered_data
    }
    ] = useMutation(customerActivateByUrl);

    const submit = (e) => {
        e.preventDefault();
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setSomethingUpadating(false);
        if (!user_password) {
            setErrorMessage("Fields are required!")
        }
        else if (user_password !== conf_password) {
            setErrorMessage("Both password should be same");
        }
        else {
            setSomethingUpadating(true);
            ResetPassword({
                variables: {
                    activationUrl: ResetUrl,
                    password: user_password
                }
            })
        }
    }


    const openLoginPopup = () => {
        props.ShowLoginPopup();
    }



    useEffect(() => {
        if (recovered_data !== undefined) {
            if (recovered_data.customerActivateByUrl.customerUserErrors !== undefined && recovered_data.customerActivateByUrl.customerUserErrors.length) {
                recovered_data.customerActivateByUrl.customerUserErrors.forEach(function (error) {
                    setErrorMessage(error.message);
                    setSomethingUpadating(false);
                })
            }
            else {
                //update register form detail to user
                axios.get(props.EngineURL + 'create-account/activate_account_new.php?userid=' + userid + '&token=' + ResetUrl)
                    .then(function (response) {

                        setTimeout(function () {
                            if (response.data.code === 200) {

                                if (response.data.message === "user account activated") {
                                    setSuccessMessage("Your account has been activated successfully! Please login now.");
                                }
                                else {
                                    setSuccessMessage(response.data.message);
                                }


                             /* Refreral code part is on Hold

                                axios.get(props.EngineURL + 'create-account/send_refreral_code.php?email=' + userEmail+'&discount_type=percentage&discount_value=10')
                                    .then(function (response2) {
                                        if (response2.status === 200) {

                                          
                                        }

                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                    });*/
                            }
                            else {
                                setErrorMessage(response.data.message);
                            }
                            setSomethingUpadating(false);
                        }, 1000);

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                setSomethingUpadating(false);
                setSuccessMessage("Your Account has been activated successfully !");
            }
        }

    }, [recovered_data])


    return (
        <Container>
            <br />
            <Row className="justify-content-center">
                <Col md={4} xs={12}>

                    <Form className="" onSubmit={submit}>
                        <Modal.Title className="text-center">Activate your account</Modal.Title>
                        {SuccessMessage === undefined ? <>
                            <Form.Group className="mb-3" >
                                <Form.Control type="password" onChange={(e) => set_user_password(e.target.value)} value={user_password} placeholder="Enter your new password" />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Control type="password" onChange={(e) => set_conf_password(e.target.value)} value={conf_password} placeholder="Confirm your password" />
                            </Form.Group></> : ''}

                        {ErrorMessage !== undefined ?
                            <Alert variant="danger">
                                {ErrorMessage}
                            </Alert>
                            : ''}
                        {SuccessMessage !== undefined ? <>
                            <Alert variant="success">
                                {SuccessMessage}
                            </Alert>
                            <Button className="w-100 rounded-2 btn-lg border-2" onClick={(e) => openLoginPopup()}>Login</Button></>
                            : ''}
                        {SomethingUpadating === true ?
                            <Alert variant="primary">
                                Validating...
                            </Alert>
                            : ''}
                        {SuccessMessage === undefined ? <Button variant="primary" type="submit" className="w-100 rounded-2 btn-lg border-2">Submit</Button> : ''}
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}
