import React, { useState, useEffect } from "react";
import {
  // Card,
  Button,
  Modal,
  Container,
  Row,
  Col,
  Alert,
  // ModalBody,
  Form,
} from "react-bootstrap";

import { useMutation } from "@apollo/react-hooks";
import { useLocation } from "react-router";
import queryString from "query-string";
import { customerResetByUrl } from "../grappql/Customers/Mutations/customerResetByUrl";

export const ResetPassword = (props) => {
  const location = useLocation();

  const queryParam = queryString.parse(location.search);

  if (queryParam["reset_url"] === undefined) {
    window.location.href = "/";
  }

  const ResetUrl =
    queryParam["reset_url"] !== undefined && queryParam["reset_url"] !== null
      ? queryParam["reset_url"]
      : undefined;

  const [user_password, set_user_password] = useState("");
  const [conf_password, set_conf_password] = useState("");

  const [ErrorMessage, setErrorMessage] = useState(undefined);
  const [SuccessMessage, setSuccessMessage] = useState(undefined);
  const [SomethingUpadating, setSomethingUpadating] = useState(false);

  const [ResetPassword, { data: recovered_data }] =
    useMutation(customerResetByUrl);

  const submit = (e) => {
    e.preventDefault();
    setErrorMessage(undefined);
    setSuccessMessage(undefined);
    setSomethingUpadating(false);
    if (!user_password) {
      setErrorMessage("Fields are required!");
    } else if (user_password !== conf_password) {
      setErrorMessage("Both password should be same");
    } else {
      setSomethingUpadating(true);
      ResetPassword({
        variables: {
          password: user_password,
          resetUrl: ResetUrl,
        },
      });
    }
  };

  const openLoginPopup = () => {
    props.ShowLoginPopup();
  };

  useEffect(() => {
    if (recovered_data !== undefined) {
      if (
        recovered_data.customerResetByUrl.customerUserErrors !== undefined &&
        recovered_data.customerResetByUrl.customerUserErrors.length
      ) {
        recovered_data.customerResetByUrl.customerUserErrors.forEach(function (
          error
        ) {
          setErrorMessage(error.message);
          setSomethingUpadating(false);
        });
      } else {
        setSomethingUpadating(false);
        setSuccessMessage("Your password has been updated successfully !");
      }
    }
  }, [recovered_data]);

  return (
    <Container>
      <br />
      <Row className="justify-content-center">
        <Col md={4} xs={12}>
          <Form className="" onSubmit={submit}>
            <Modal.Title className="text-center">Reset Password</Modal.Title>
            {SuccessMessage === undefined ? (
              <>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="password"
                    onChange={(e) => set_user_password(e.target.value)}
                    value={user_password}
                    placeholder="Enter your new password"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="password"
                    onChange={(e) => set_conf_password(e.target.value)}
                    value={conf_password}
                    placeholder="Confirm your password"
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}

            {ErrorMessage !== undefined ? (
              <Alert variant="danger">{ErrorMessage}</Alert>
            ) : (
              ""
            )}
            {SuccessMessage !== undefined ? (
              <>
                <Alert variant="success">{SuccessMessage}</Alert>
                <Button
                  className="w-100 rounded-2 btn-lg border-2"
                  onClick={(e) => openLoginPopup()}>
                  Login
                </Button>
              </>
            ) : (
              ""
            )}
            {SomethingUpadating === true ? (
              <Alert variant="primary">Validating...</Alert>
            ) : (
              ""
            )}
            {SuccessMessage === undefined ? (
              <Button
                variant="primary"
                type="submit"
                className="w-100 rounded-2 btn-lg border-2">
                Submit
              </Button>
            ) : (
              ""
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
