import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
    LocationIcon,
    DollarIcon,
    ClockIcon,
    LinkedInIcon,
    PointInIcon,
    CalendarIcon,
    CaretUpIcon,
    CaretDownIcon,
    ArrowUpIcon,
    ArrowDownIcon,
    FaceBookIcon,
    TwitterIcon,
    LinkedFIcon,
    MessageIcon,
    PrintIcon,
    ShareIcon,
    LikeIcon
} from "../../../icons";
import {
    Container,
    Row,
    Col,
    ListGroup,
    Modal,
    Accordion,
    Form,
    Button
} from 'react-bootstrap';
import programimg from '../../../images/program-img.jpg';
import avtar1 from '../../../images/avtar-1.png';
import avtar2 from '../../../images/avtar-2.png';
import user_default_image from '../../../images/user.png';

import pm from '../../../images/pm-ic.png';
import ps from '../../../images/ps-ic.png';
import sl from '../../../images/sl-ic.png';
import ep from '../../../images/ep-ic.png';
import { PresentorModal } from '../../Modals/Presentor';

// import Swiper core and required modules
import SwiperCore, {
    Navigation
} from 'swiper';
// install Swiper modules
SwiperCore.use([Navigation]);
const TypeBook = (props) => {


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [showPresentorModal, setShowPresentorModal] = useState(false);
    const [presentor_modal_data, set_presentor_modal_data] = useState({ name: '', image: '', job: '', description: '', linkedin: '', author_company_title: '' });
    const show_presentor_modal = () => {
        setShowPresentorModal(true);
    }
    const hide_presentor_modal = () => {
        setShowPresentorModal(false);
    }

    useEffect(() => {
        if (!show) {
            props.RevetCopyText();
        }
    }, [show])
    const handleShow = () => setShow(true);
    let top_author_index = 0;


    // new api datas
    let title = props.single_program_basic_data.title !== undefined ? props.single_program_basic_data.title : '';
    let handlee = props.handle !== undefined ? props.handle : '';
    let description = props.single_program_basic_data.description !== undefined ? props.single_program_basic_data.description : '';
    let thumbnail = programimg;
    let date_line1 = props.single_program_data.Program_Date_Time_Line_1__c != '' ? props.single_program_data.Program_Date_Time_Line_1__c : '';
    let date_line2 = props.single_program_data.Program_Date_Time_Line_2__c != '' ? props.single_program_data.Program_Date_Time_Line_2__c : '';
    let Designed_For__c = props.single_program_data.Designed_For__c != '' ? props.single_program_data.Designed_For__c : '';
    let Catering = props.single_program_data.Catering__c != '' ? props.single_program_data.Catering__c : '';
    let Venue__c = props.single_program_data.Venue__c != '' && props.single_program_data.Venue__c != 'Live Stream Only' ? props.single_program_data.Venue__c : '';
    let Venue_link = Venue__c != '' && Venue__c != 'Live Stream Only' ? 'https://www.google.com/maps/search/?api=1&query=' + Venue__c : 'javascript:;';
    let Ethics = props.single_program_data.Ethics_Professional_Responsibility__c > 0 ? props.single_program_data.Ethics_Professional_Responsibility__c : 0;
    let practice_managment = props.single_program_data.Practice_Management_Business_Skills__c > 0 ? props.single_program_data.Practice_Management_Business_Skills__c : 0;
    let professional_skills = props.single_program_data.Professional_Skills__c > 0 ? props.single_program_data.Professional_Skills__c : 0;
    let Substantive_Law = props.single_program_data.Substantive_Law__c > 0 ? props.single_program_data.Substantive_Law__c : 0;
    let total_points = parseFloat(Ethics) + parseFloat(practice_managment) + parseFloat(professional_skills) + parseFloat(Substantive_Law);
    let Short_Promotion_Message__c = props.single_program_data.Short_Promotion_Message__c != '' ? props.single_program_data.Short_Promotion_Message__c : '';
    let Long_Promotion_Message__c = props.single_program_data.Long_Promotion_Message__c != '' ? props.single_program_data.Long_Promotion_Message__c : ''
    let delivery_mode = props.single_program_data.delivery_mode != '' ? props.single_program_data.delivery_mode : undefined;
    let product_code = props.single_program_basic_data.product_code !== undefined && props.single_program_basic_data.product_code != '' ? props.single_program_basic_data.product_code : '';
    let product_price = props.single_program_basic_data.price !== undefined && props.single_program_basic_data.price != '' ? props.single_program_basic_data.price : 0;
    let related_program = props.single_program_data.related_program.length > 0 ? props.single_program_data.related_program : [];
    let presentor = props.single_program_data.presentor.length > 0 ? props.single_program_data.presentor : [];
    let start_date_time = props.single_program_data.start_date_time != '' ? props.single_program_data.start_date_time : '';
    let end_date_time = props.single_program_data.end_date_time != '' ? props.single_program_data.end_date_time : '';
    let Publication_Date__c = props.single_program_data.Publication_Date__c != '' ? props.single_program_data.Publication_Date__c : '';
    let ISBN__c = props.single_program_data.ISBN__c != '' ? props.single_program_data.ISBN__c : '';
    let Pages__c = props.single_program_data.Pages__c > 0 ? props.single_program_data.Pages__c : 0;
    let product_image_url = props.single_program_data.product_image_url !== null ? props.single_program_data.product_image_url : programimg;
    const Program_Structure__c = props.single_program_data.Program_Structure__c !== '' && props.single_program_data.Program_Structure__c !== null && props.single_program_data.Program_Structure__c !== undefined ? props.single_program_data.Program_Structure__c : null;
    const Early_Bird_Price__c = props.single_program_data.Compare_Price != '' && props.single_program_data.Compare_Price !== null ? props.single_program_data.Compare_Price : undefined;

    const [DefaultRadio, setDefaultRadio] = useState(delivery_mode === undefined || delivery_mode.length==0 ? '' : delivery_mode[0]);



    if (props.single_program_data.file.length > 0) {
        props.single_program_data.file.forEach((file_val, file_key) => {
            if (file_val.NEILON__Category__c == "Product Image") {
                thumbnail = file_val.NEILON__File_Presigned_URL__c != '' ? file_val.NEILON__File_Presigned_URL__c : programimg;
            }
        });
    }

    // new api datas end
    const price_float = parseFloat(product_price);

    const numberWithCommas = (x) => {
        return parseFloat(x).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div className={'single_program_page mbook ' + props.product_page_type}>
            <Container>
                <div className="single_program_wrapper position-relative">
                    <Row>
                        <Col xl={8} lg={7} xs={12}>
                            <div className="cpd_single_programs_content">
                                <div className="single_programs_head">
                                    <h1 className="mb-0">{title} <small className="product_code d-md-none">{product_code}</small></h1>
                                </div>
                                <div className="single_rightside">
                                    <div className="single_rightside_inner">
                                        <div className="program_right_cart">
                                            <div className="program_right_cart_head d-flex flex-column flex-sm-row justify-content-between">
                                                <h2 className="text-primary d-none d-md-block">{title} <small className="product_code">{product_code}</small></h2>
                                                <div className="program_price d-none d-md-inline-block fsadfsfd">${numberWithCommas(price_float)}<br /><span className="discount_price">{Early_Bird_Price__c !== undefined ? '$' + numberWithCommas(Early_Bird_Price__c) : null}</span></div>
                                            </div>
                                            <div className='wishlist_and_short_sms d-flex flex-wrap align-items-center'>
                                                <button className={props.getAllWishlist.includes(handlee) ? 'wishlist_link wishlist_link_add' : 'wishlist_link'} onClick={() => props.AddtoWishlist(handlee, start_date_time)}> {props.getAllWishlist.includes(handlee) ? <> <LikeIcon /> Added</> : <> <LikeIcon /> Add to wishlist</>}</button>

                                                {!props.reached_capacity
                                                    ?
                                                    Short_Promotion_Message__c != '' && Short_Promotion_Message__c !== null ?
                                                        <ListGroup horizontal as="ul" className='short_promotion_message ms-3'>
                                                            <ListGroup.Item as="li"><div className="label_tag p-0">{Short_Promotion_Message__c}</div></ListGroup.Item>
                                                        </ListGroup>
                                                        : null
                                                    : ''}
                                            </div>

                                            <div className="length_block list_block">
                                                {
                                                    Venue__c != '' && Venue__c !== null ?
                                                        <ListGroup horizontal as="ul" className="address_block">
                                                            <a target={Venue_link != 'javascript:;' ? '_blank' : '_self'} href={Venue_link}><ListGroup.Item as="li"><LocationIcon /> {Venue__c}</ListGroup.Item></a>
                                                        </ListGroup>
                                                        : null
                                                }
                                                {
                                                    date_line1 !== '' && date_line1 !== null
                                                        ?
                                                        <>
                                                            {date_line1 != '' ? <small className="d-flex"><CalendarIcon /> {date_line1}</small> : null}
                                                        </>
                                                        : null
                                                }
                                                {
                                                    date_line2 !== '' && date_line2 !== null
                                                        ?
                                                        <>
                                                            {date_line2 != '' ? <small className="d-flex"><ClockIcon /> {date_line2}</small> : null}
                                                        </>

                                                        : null
                                                }
                                                <a className='print_info' onClick={() => window.print()}><PrintIcon />Print Course Information</a>
                                                {
                                                    Long_Promotion_Message__c != '' && Long_Promotion_Message__c !== null ?
                                                        <p className="price_sale d-flex"><ClockIcon stroke='#fe1010' /> {Long_Promotion_Message__c}</p>
                                                        : null
                                                }

                                            </div>
                                            <div className="quantity_block list_block">
                                                <div className="program_right_cart_head mb-3">
                                                    <div className="program_price d-md-none"><span className="discount_price">{Early_Bird_Price__c !== undefined ? '$' + numberWithCommas(Early_Bird_Price__c) : null}</span>${numberWithCommas(price_float)}</div>
                                                </div>
                                                {
                                                    !props.reached_capacity ?
                                                        <a onClick={(e) => props.AddtoCart(props.SingleVariants.id, props.quantity > 0 ? props.quantity : 1, props.SingleVariants.sku, DefaultRadio, props.recordType, props.tags, props.Program_structure_image, handlee, start_date_time, Program_Structure__c)} className="btn btn-primary"> Add to Cart</a>
                                                        : <strong>Fully Booked</strong>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <a className="share_colleagues_link d-flex align-items-center justify-content-center" onClick={handleShow}><ShareIcon />Share with Colleagues</a>
                                </div>
                                <Accordion defaultActiveKey="0">
                                    {
                                        presentor.length ?
                                            <Accordion.Item eventKey="0" className='presenters-accordion'>
                                                <Accordion.Header>Presenters</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="author_group">
                                                        <Row xs={1} xl={2}>
                                                            {
                                                                presentor.map((top_author) => {
                                                                    top_author_index = top_author_index + 1;
                                                                    let author_name = top_author.Contact_info !== undefined && top_author.Contact_info[0] !== undefined ? top_author.Contact_info[0].Name : null;
                                                                    let author_image = top_author.Contact_info !== undefined && top_author.Contact_info[0] !== undefined ? top_author.Contact_info[0].Presenter_Profile_Photo_URL__c : null;
                                                                    let author_role = top_author.Role__c !== undefined && top_author.Role__c !== null ? top_author.Role__c : null;
                                                                    let author_job_title = top_author.Contact_info !== undefined && top_author.Contact_info[0] !== undefined && top_author.Contact_info[0]['Job_Title_Position__c'] !== null ? top_author.Contact_info[0]['Job_Title_Position__c'] : '';
                                                                    let author_account_name = top_author.Contact_info !== undefined && top_author.Contact_info[0] !== undefined && top_author.Contact_info[0]['Account_Name'] !== null ? top_author.Contact_info[0]['Account_Name'] : '';
                                                                    let author_top_job_account = [];
                                                                    if (author_job_title !== '') {
                                                                        author_top_job_account = [...author_top_job_account, author_job_title];
                                                                    }
                                                                    if (author_account_name !== '') {
                                                                        author_top_job_account = [...author_top_job_account, author_account_name];
                                                                    }

                                                                    return <Col key={top_author_index}>
                                                                        <div className="author_card">
                                                                            <div className="author_card_head">
                                                                                <img src={author_image == undefined || author_image == null ? user_default_image : author_image} alt={author_image} />
                                                                                <p>{author_name}</p>
                                                                                <small>{author_top_job_account.join(', ')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                })
                                                            }
                                                        </Row>
                                                    </div>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            : null
                                    }
                                    {
                                        description !== '' && description !== null
                                            ?
                                            <Accordion.Item eventKey={presentor.length ? "1" : "0"} className='description_accordion'>
                                                <Accordion.Header>Description</Accordion.Header>
                                                <Accordion.Body>

                                                    <div className="programs_description">
                                                        <div dangerouslySetInnerHTML={{ __html: description }} />
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            : null
                                    }
                                    <Accordion.Item eventKey="2" className='publication_details_accordion'>
                                        <Accordion.Header>Publication Details</Accordion.Header>
                                        <Accordion.Body>
                                            {
                                                (Publication_Date__c !== null) || (ISBN__c !== null) && (Pages__c !== null) ?

                                                    <div className="publication_details_wrapper">
                                                        <div className="publication_details_card card border-light">

                                                            <div className="publication_details_list">
                                                                <ListGroup  as="ul" className="justify-content-between">
                                                                    {
                                                                        Publication_Date__c != ''
                                                                            ?
                                                                            <ListGroup.Item as="li" className="me-3 price_item  mb-4">
                                                                                <strong>Publication Date :</strong>  {Publication_Date__c}
                                                                            </ListGroup.Item>
                                                                            : null
                                                                    }
                                                                    {
                                                                        ISBN__c != ''
                                                                            ?
                                                                            <ListGroup.Item as="li" className="me-3 time_item  mb-4">
                                                                                <strong>ISBN :</strong>  {ISBN__c}
                                                                            </ListGroup.Item>
                                                                            : null
                                                                    }
                                                                    {
                                                                        Pages__c > 0
                                                                            ?
                                                                            <ListGroup.Item as="li" className="location_item  mb-4">
                                                                                <strong>Pages :</strong>  {Pages__c} Pages
                                                                            </ListGroup.Item>
                                                                            : null
                                                                    }
                                                                </ListGroup>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Col>
                        <Col xl={4} lg={5} xs={12}>
                        </Col>
                    </Row>
                </div>


                {
                    related_program !== undefined && related_program.length > 0 ?
                        <div className="related_courses_wrapper">
                            <h2 className="text-center">Related Programs</h2>
                            <Swiper
                                spaceBetween={20}
                                slidesPerView={3}
                                navigation={true}
                                // pagination={{
                                //     "clickable": true
                                //   }}
                                breakpoints={{
                                    "0": {
                                        "slidesPerView": 1,
                                    },
                                    "768": {
                                        "slidesPerView": 2,
                                    },
                                    "1200": {
                                        "slidesPerView": 3,
                                        "spaceBetween": 44,
                                    }
                                }}
                            >
                                {
                                    related_program.map((related_program) => {
                                        const desc = related_program.Description__c !== undefined && related_program.Description__c !== null ? related_program.Description__c.substring(0, 150) : null;
                                        const rdata_permalink = 'product/' + related_program.Slug__c !== null ? related_program.Slug__c : '';
                                        if (related_program.Related_Program__c != '') {
                                            return <SwiperSlide key={Math.random()}>
                                                <Link to={rdata_permalink} className="d-block courses_card">
                                                    <div className="courses_card_content card border-light">
                                                        <h4>{related_program.Related_Program__c}</h4>
                                                        <div dangerouslySetInnerHTML={{ __html: desc }} />
                                                        <ListGroup horizontal as="ul" className="justify-content-between">
                                                            <ListGroup.Item as="li" className="me-3 price_item">
                                                                <DollarIcon /> {parseFloat(related_program.Store_Price__c).toFixed(2)}<span>(Including GST)</span>
                                                            </ListGroup.Item>
                                                            {/* <ListGroup.Item as="li" className="me-3 time_item"><ClockIcon /> Full Day</ListGroup.Item> */}
                                                            {related_program.Venue__c != '' && related_program.Venue__c !== null ? <ListGroup.Item as="li" className="me-3 location_item"><LocationIcon /> {related_program.Venue__c}</ListGroup.Item> : null}
                                                        </ListGroup>
                                                    </div>
                                                </Link>
                                            </SwiperSlide>
                                        }
                                    })
                                }

                            </Swiper>
                        </div>
                        : null
                }
            </Container>
            <>
                <Modal show={show} onHide={handleClose} className="share_modal">
                    <Modal.Header closeButton className="border-0">
                    </Modal.Header>
                    <Modal.Body>
                        <Modal.Title className="text-center">Share This Program</Modal.Title>
                        <ListGroup horizontal as="ul" className="social_media_list justify-content-center">
                            <ListGroup.Item as="li" className="social_item" onClick={() => props.FBshare()}>
                                <FaceBookIcon fill="#004e6d" />
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="social_item" onClick={() => props.TwitterShare()}>
                                <TwitterIcon fill="#004e6d" />
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="social_item" onClick={() => props.LinkedinShare()}>
                                <LinkedFIcon fill="#004e6d" />
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="social_item" onClick={() => props.emailShare()}>
                                <MessageIcon fill="#004e6d" />
                            </ListGroup.Item>
                        </ListGroup>
                        <a onClick={() => props.CopyLink()} className="btn btn-primary w-100">{props.Clipboard}</a>
                    </Modal.Body>
                </Modal>
            </>
            <PresentorModal presentor_modal_data={presentor_modal_data} show={showPresentorModal} hide={hide_presentor_modal} />
        </div>
    )
}

export default TypeBook;