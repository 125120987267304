
import gql from "graphql-tag";

import { CheckoutFragment } from "../fragments";
/*
export const checkoutLineItemsAdd = gql`
  mutation checkoutLineItemsAdd(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemInput!]!
  ) {
    checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;*/


export const checkoutLineItemsAdd = gql`
mutation cartLinesAdd($checkoutId: ID!, $lines: [CartLineInput!]!) {
  cartLinesAdd(cartId: $checkoutId, lines: $lines) {
    cart {
       ...CheckoutFragment
    }
    userErrors {
      field
      message
    }
  }
}
${CheckoutFragment}
`;