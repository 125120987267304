import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Document } from 'react-pdf'
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import {
    MyLibraryIcon,
    MyEventsIcon,
    CPDPointsIcon,
    OrdersIcon,
    ProfileIcon,
    SearchIcon,
    PlayIcon,
    DownloadIcon,
    LoaderIcon,
    LogOutIcon,
    InformationIcon
} from "../../icons";
import {
    Container,
    Row,
    Col,
    ListGroup,
    Form,
    FormControl,
    Button,
    Card,
    Modal
} from 'react-bootstrap';
import { Helmet } from "react-helmet";
import { AdminSidebar } from './Sidebar/AdminSidebar';

import BlueBg from "../../images/my-profile-banner.png";

export const AdminMyLibrary = (props) => {
    // console.log('library_leo', props.Program_structure_images);
    let history = useHistory();
    const My_Account_button_click = () => {
        if (props.useLoggedIn)
            history.push('/admin-my-library');
        else
            props.ShowLoginPopup_();
    }

    const [show, setShow] = useState(false);
    const [update, setUpdate] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const setUpdatehandleClose = () => setUpdate(false);
    const setUpdatehandleShow = () => setUpdate(true);

    // Orders states
    const [myPrograms, setMyPrograms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const libraryPageMenu = useRef('');
    const searchRef = useRef('');
    const program_structure_default_image = 'https://s3.ap-southeast-2.amazonaws.com/leo-cussen-store-product-images/Cards/CPD%20Seminar.jpg';


    const [showPopup, setShowPopup] = useState(false);
    const [video_link, setVideoLink] = useState('');
    // Handle Popup
    const handlePopup = (video_link = '') => {
        setShowPopup(!showPopup);
        setVideoLink(video_link);
    }

    const [user_email, set_user_email] = useState('');
    const [user_token, set_user_token] = useState('');
    const [error, set_error] = useState('');
    const [user_lastname, set_user_lastname] = useState('');

    let order_item_index = 0;
    let isMounted;
    let product_name = undefined;

    const downloadFile = (download_link) => {
        // console.log(download_link);
        // window.open(`https://leo-engine.saltandfuessel.com.au/salesforce-query/checkout/download_file.php?download_link=${download_link}`, '_blank');
        // window.location.href='https://leo-engine.saltandfuessel.com.au/salesforce-query/checkout/download_file.php';
        // axios.get('https://leo-engine.saltandfuessel.com.au/salesforce-query/checkout/download_file.php',{ crossdomain: true })
        // .then(function (response) {

        // })
    }

    useEffect(() => {
        // console.log('component rendered');
    });

    async function getData(email = '', lname = '') {
        // console.log('emailll', email);
        // console.log('lnamelname', lname);
        // console.log('user_lastnameuser_lastname', user_lastname);
        if (email == '') {
            email = user_email;
        }
        if (lname == '') {
            lname = user_lastname;
        }
        if (email != '') {
            await axios.get(`${props.EngineURL}salesforce-query/checkout/leo_library_debug.php?contact_id=${email}&search=${search}&lname=${lname}`, { crossdomain: true })
                .then(function (response) {
                    // console.log('response', response);
                    // handle success
                    if (response.data.error !== undefined && response.data.error !== '') {
                        set_error(response.data.error);
                    }
                    else {
                        set_error('');
                        setMyPrograms(response.data.program_registrations);
                        setLoading(false);
                    }
                    return response;
                })
                .catch(function (error) {
                    // handle error
                })
                .then(function () {
                    // always executed
                });
        }

    }

    useEffect(() => {
        isMounted = true;
        let orders_data = getData(user_email);



        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (search == '') {
            setSearch('');
            getData(user_email);
        }
    }, [search]);



    useEffect(() => {
        var userDetail = (localStorage.getItem("userDetail"));
        var userLoginData = JSON.parse(userDetail);
        let user_lname = '';
        let accessToken = '';
        if (props.UserData != undefined && props.UserData !== null && props.UserData.customer !== null) {
            // console.log('setting');
            set_user_lastname(props.UserData.customer.lastName);
            user_lname = props.UserData.customer.lastName;
            // console.log('get_user_lname', user_lname);
            // console.log('user_lastname', user_lastname);

        }
        if (userLoginData !== null && userLoginData !== undefined) {
            var login_user_email = userLoginData.email;
            accessToken = userLoginData.accessToken;
            if (login_user_email !== undefined && login_user_email !== '') {
                set_user_email(userLoginData.email);
            }
            if (accessToken !== undefined && accessToken !== '' && user_lname != '') {

                set_user_token(userDetail.accessToken);
            }
        }
        if (accessToken !== undefined && accessToken !== '' && user_lname != '') {
            set_user_lastname(user_lname);
            // console.log('user_lnamedddd', user_lname);
            let orders_data = getData(login_user_email, user_lname);
        }
        // console.log('my props', props.UserData);
    }, [props.UserData]);

    const doLogout = () => {
        localStorage.removeItem("userDetail");
        localStorage.setItem('isLoggedin', false);
        localStorage.removeItem("checkout_token");
        localStorage.removeItem("thank_you_image");
        localStorage.removeItem("checkout_id");
        localStorage.removeItem("cart_session");
        localStorage.removeItem("create_account_detail");
        localStorage.removeItem("cart_session_sku");
        localStorage.removeItem("isLoggedin");
        localStorage.removeItem("was_book");
        localStorage.removeItem("wishlist_item");
        window.location.href = "/";
    }

    const reRenderPage = () => {
        setLoading(true);
        setSearch('');
        searchRef.current.value = '';
        // getData(user_email);
    }



    return (
        <div className="admin_page admin_my_library" style={{ backgroundImage: "url(" + BlueBg + ")" }}>
            <Helmet>
                <title>My Webcast Library | Leo Cussen Centre for Law</title>
            </Helmet>
            {
                props.useLoggedIn !== undefined && props.useLoggedIn == true && error == ''
                    ?
                    <Container>
                        {/* {user_email} */}
                        <div className="admin_wrapper position-relative">
                        {props.UserData !== null && props.UserData !== undefined ?
                                <div className='admin_details_block mb-5'>
                                    <h1 className="m-0">{props.Good},  {props.UserData.customer.firstName}</h1>
                                </div>
                                : null}
                            <Row>
                                <Col xl={3} lg={4} xs={12} className="d-none d-lg-block">
                                    <AdminSidebar doLogout={doLogout} />
                                </Col>
                                <Col xl={9} lg={8} xs={12}>
                                    <div className='admin_sidebar_mobile d-lg-none'>
                                        <AdminSidebar doLogout={doLogout} />
                                    </div>
                                    <div className="admin_right_content_block">
                                        <div className="information_block card border-exlight">
                                            <div className="information_content">
                                                <InformationIcon />
                                                <p className="small mb-0">
                                                    <strong>Disclaimer</strong>
                                                </p>
                                                <p className="mt-2 small small-dec mb-0">CPD customers please note that the digital products in your library are retained for the current and previous CPD years (1 April – 31 March). Download them to ensure access beyond this time. For enquiries contact <a href="mailto:registration@leocussen.edu.au">registration@leocussen.edu.au</a>.</p>

                                            </div>
                                        </div>
                                        <div className="admin_head d-flex flex-wrap align-items-center justify-content-between">
                                            <h4 className="m-0 text-dark">My Webcast Library</h4>
                                            <Form className="d-flex search_block" onSubmit={(e) => {
                                                e.preventDefault();
                                                getData();
                                                setLoading(true);
                                            }}>
                                                <div className="search_group position-relative">
                                                    <FormControl
                                                        type="search"
                                                        placeholder="Type and enter to search"
                                                        className="search_filed"
                                                        aria-label="Search"
                                                        ref={searchRef}
                                                        onKeyUp=
                                                        {
                                                            (e) => {
                                                                setSearch(e.target.value);
                                                                if (e.target.value == '') {
                                                                    setSearch('');
                                                                    setLoading(true);
                                                                    getData(user_email);
                                                                }
                                                            }
                                                        }
                                                    />
                                                    <Button variant="search"><SearchIcon /></Button>
                                                </div>
                                            </Form>
                                        </div>


                                        {
                                            loading === false
                                                ?
                                                myPrograms !== undefined && myPrograms.length > 0 ?
                                                    myPrograms.map((program_item) => {
                                                        // console.log('program_item', program_item);
                                                        let product_name = program_item.program_name;
                                                        let product_name_slug = program_item.program_slug !== undefined && program_item.program_slug !== null ? program_item.program_slug : '';
                                                        let program_attendees = program_item.attendees;
                                                        let program_downloads = program_item.program_downloads;
                                                        let program_structure = program_item.program_structure.toLowerCase().split(' ').join('-');
                                                        let program_structure_image = program_structure_default_image;
                                                        // console.log('program_structure',program_structure);
                                                        props.Program_structure_images.filter(function (item, value) {
                                                            var keyname = Object.keys(item);
                                                            //console.log('keyname',keyname);
                                                            if (keyname.includes(program_structure)) {
                                                                if (item[program_structure] !== undefined && item[program_structure] !== null && item[program_structure].length > 0) {
                                                                    program_structure_image = item[program_structure];
                                                                }
                                                            }
                                                        })
                                                        return <Card className="admin_library_card shadow border-light" key={product_name}>
                                                            <Card.Body>
                                                                <div className="library_cate d-md-flex justify-content-center flex-column">
                                                                    <img src={program_structure_image} alt={program_structure} />
                                                                </div>
                                                                <Link to={'/product/' + product_name_slug}><Card.Title as="h6">{product_name}</Card.Title></Link>
                                                                <Card.Text as="div">
                                                                    {
                                                                        program_attendees.length > 0 ?

                                                                            <ListGroup horizontal as="ul">
                                                                                <ListGroup.Item as="li" className="attendee">
                                                                                    Attendees: {program_attendees.join(', ')}

                                                                                </ListGroup.Item>
                                                                            </ListGroup>
                                                                            : null
                                                                    }
                                                                    {
                                                                        program_downloads !== undefined && program_downloads.length > 0
                                                                            ?
                                                                            <div className="library_video_block">
                                                                                <ListGroup as="ul">
                                                                                    {
                                                                                        program_downloads.map((program_downloads_item) => {
                                                                                            let media_name = program_downloads_item.Name;
                                                                                            let media_download_link = program_downloads_item.NEILON__File_Presigned_URL__c;
                                                                                            let media_type = program_downloads_item.NEILON__Content_Type__c.split('/').reverse()[0];
                                                                                            return <ListGroup.Item as="li" className="" key={media_name}>
                                                                                                <div className="library_icons">
                                                                                                    {media_type == 'mp4' || media_type == 'm4v' || media_type == 'mp4v' ? <span className="libraryPlayIcon" onClick={() => handlePopup(media_download_link)}><PlayIcon /></span> : null}
                                                                                                    <a href={media_download_link} target="_blank" ><DownloadIcon /></a>
                                                                                                </div>
                                                                                                <a href={media_download_link} target="_blank"><span onClick={() => downloadFile(media_download_link)}>{media_name}</span></a>
                                                                                            </ListGroup.Item>
                                                                                        })

                                                                                    }
                                                                                </ListGroup>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    })
                                                    : <div className='not_found bg-white text-primary rounded text-center p-3 border'><h6 className='h3 mb-0'>No records found</h6></div>

                                                : <div className="pre_loader"><LoaderIcon /></div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div >
                    </Container >
                    :
                    <div className="text-center">
                        {
                            error != ''
                                ?
                                <h3 className="text-center">
                                    {
                                        error == 'user not found.' ?
                                            <p>There are no data found with your account. <a href='mailto:registration@leocussen.edu.au' className="btn-link">Get in touch</a></p>
                                            : 'Please login to access My profile section.'
                                    }
                                </h3>
                                : null
                        }
                        {
                            error == ''
                                ?
                                <>
                                    <h3>Please Login</h3>
                                    <a onClick={() => My_Account_button_click()} className="btn btn-primary rounded-pill m-0 stest"> Login</a>
                                </>
                                : null
                        }
                    </div>
            }
            <>
                <Modal show={showPopup} size="lg" onHide={handlePopup} className="need_help_modal">
                    <Modal.Header closeButton className="border-0">
                    </Modal.Header>
                    <Modal.Body>
                        <video width="100%" height="100%" controls>
                            <source src={video_link} type="video/mp4" />
                            <source src={video_link} type="video/ogg" />
                        </video>
                    </Modal.Body>
                </Modal>
            </>
        </div >
    )
}
