import gql from "graphql-tag";
export const customerAccessTokenCreate = gql`
  mutation customerAccessTokenCreate(
    $userEmailPass: CustomerAccessTokenCreateInput!
  ) {
    customerAccessTokenCreate(input: $userEmailPass) {
      customerUserErrors {
        field
        message
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
    }
  }
`;